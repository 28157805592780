import styled from "styled-components"

export const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const MainWrapper = styled.div`
  --header-height: 44px;
  --cta-height: 84px;
  --header-padding-top: 6px;

  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing_xl, 24px);

  height: calc(100% - var(--header-height) - var(--cta-height) - var(--header-padding-top));
  overflow-y: auto;
`

export const CtaBox = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_xl, 24px)
    var(--spacing_l, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`
