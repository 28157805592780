import { Link } from "react-router-dom"
import styled from "styled-components"

export const OnboardingWrapper = styled.div`
  padding-bottom: 0px;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;

  --header-height: 44px;
  --cta-box-height: 88px;
  background: var(--Schemes-Surface-Container-Low, #f9fbfc);
`

export const OnboardingContent = styled.div`
  padding-top: 12px;
  margin-bottom: auto;
  height: calc(100% - var(--header-height) - var(--cta-box-height) - 6px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px 8px;
`

export const CTABox = styled.div`

  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;
  padding: var(--spacing_l, 16px) 8px var(--spacing_xl, 24px) 8px;

  & > * {
    width: 100%;
  }
`

export const ToPersonalLink = styled(Link)`
  color: var(--color-dark);
  display: flex;
  align-items: center;
  text-decoration: none;
`
