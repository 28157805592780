import styled from "styled-components"
export const FoodDetailsWrapper = styled.div``

export const StyledMealDetails = styled.div`
  --footer-height: 68px;
  --header-padding-top: 6px;

  padding: 16px 8px;
  overflow-y: auto;
  height: calc(100% - var(--header-height) - var(--footer-height) - var(--header-padding-top));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const PageTitle = styled.div`
  font-family: sf-pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 590;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  text-transform: capitalize;
`

export const FoodImageBox = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;

  .close-preview-btn {
    position: fixed;
    top: 15px;
    right: 16px;
    z-index: 1000;
    cursor: pointer;
  }
`

export const FoodImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const FoodImagePlaceholder = styled.img`
  width: 100%;
  height: 262px;
  object-fit: cover;
  position: absolute;
  top: -50px;
`

export const FoodImageOverlay = styled.div`
  background: linear-gradient(180deg, rgba(27, 20, 1, 0) 0%, #1b1401 100%);
  position: absolute;
  inset: 0px;
`

export const FoodName = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: var(--Schemes-Surface-Container-Lowest, #fff);

  position: absolute;
  top: 84px;
  text-align: center;
  width: 100%;

  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const IngredientBox = styled.div`
  width: calc(100% - 16px);
  margin: 0 auto;

  border-radius: 8px;
  position: relative;
  margin-top: 16px;
`

export const NutritionInfo = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_l, 16px)
    var(--spacing_l, 16px);
  flex-direction: column;
  gap: var(--spacing_m, 12px);
`

export const NutritionInfoTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`
