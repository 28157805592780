export type IActive = "LIGHTLY_ACTIVE" | "ACTIVE" | "VERY_ACTIVE"
export type IOnboardingTypeName = "WEIGHT_LOSS" | "MAINTAIN" | "BUILDING" |"NOT_SURE"
export type IWeeklyGoal = number

export interface IOnboardingPayload {
  active: IActive
  typeName: IOnboardingTypeName
  weeklyGoal: IWeeklyGoal
  targetWeight: number
}

export interface IOnboardingServerPayload {
  type_name: IOnboardingTypeName
  activity_level: IActive
  weight_change_per_week: number
  target_weight: number
}

export interface IUserResponse {
  gender: string
  birthedOn: string
  height: number
  weight: number
  measurement_system: IMetricType
  show_tutorial: boolean
}
export interface IUser {
  gender: string
  birthedOn: string
  height: number
  weight: number
  cmHeight: string
  kgWeight: string
  feetHeight: string
  poundWeight: string
  metricType: IMetricType
  isShowTutorial: boolean
}

export interface IResponseGoal {
  calorie_per_day: number
  pfc_balance: {
    carb: number
    fat: number
    protein: number
  }

  pfc: {
    protein: number
    fat: number
    carb: number
  }
  target_weight: number
  weight_change_per_week: number
  type_name: IOnboardingTypeName
  reached_on: string
  bmr: number
  created_at: string
}

export interface IGoal {
  caloriesPerDay: number
  protein: number
  fat: number
  carb: number

  proteinGram: number
  fatGram: number
  carbsGram: number

  targetWeight: number
  weightChangePerWeek: number
  typeName: IOnboardingTypeName
  reachedOn: string
  bmr: number
  startDate: string
}

export type IMetricType = "IMPERIAL" | "METRIC"

export interface IUpdateUserPayload {
  weight: number
  height: number
  metricType: IMetricType
}

export interface IOnboardingAPI {
  onboarding: (
    payload: IOnboardingPayload,
    metricType: IMetricType,
  ) => Promise<any>
  getGoal: () => Promise<IResponseGoal | null>
  getUser: () => Promise<IUserResponse>
  updateUser: (payload: IUpdateUserPayload) => Promise<IUserResponse>
}

export interface IOnboardingState {
  onboardingLoading: boolean
  onboardingFailed: any
  onboardingSuccess: any

  getGoalLoading: boolean
  goal?: IGoal | null
  getGoalFailed: any

  getUserLoading: boolean
  user?: IUser
  getUserFailed: any

  updateUserLoading: boolean
  updateUserFailed: any
  updateUserSuccess: any

  metricType: IMetricType

  isRevalidatedShouldShowTutorial: boolean
  isTutorialShowed: boolean
}

export enum IOnboardingStep {
  active,
  setTypeName,
  weeklyGoal,
  plan,
}
