import { Fragment, useState } from "react"
import { FlexBox } from "components/Common/FlexBox"
import ChevronLeft from "components/Icons/ChevronLeft"
import { PageTitle } from "components/Common/PageTitle"
import TextFieldFormItem from "components/TextFieldFormItem"
import { BottomCTABox, MainContent } from "./styled"
import InfoSection from "components/Common/InfoSection"
import Ingredients from "./Ingredients"
import Button from "components/Buttons/Button"
import {
  IEditMealStep,
  IFeedback,
  IIngredient,
  IPortion,
} from "features/meal/types"
import { BackButton } from "../styled"
import PlusIcon from "components/Icons/PlusIcon"
import InfoItem from "components/InfoItem"
import {
  decimalToFraction,
  getDisplayCalories,
  getDisplayNumber,
  truncateByDecimalPlace,
} from "utils"
import { useNavigate } from "react-router-dom"
import { useFeedback } from "features/meal/hooks/hooks"
import PopConfirm from "components/PopConfirm"
import InputNumberPickerView from "components/InputNumberPicker"

const EditMealGeneral = ({
  feedback,
  onSelectIngredientToEdit,
  onServingChange,
  onNameChange,
  setStep,
  isMealChanged,
}: {
  feedback: IFeedback
  onSelectIngredientToEdit: (ingredient: IIngredient) => void
  onServingChange: (portion: IPortion) => void
  onNameChange: (value: string) => void
  setStep: (step: IEditMealStep) => void
  isMealChanged: boolean
}) => {
  const navigate = useNavigate()

  const backToMealDetails = () => {
    navigate(`/meal/${feedback.id}/details`)
  }
  const { onSubmitFeedback, sendFeedbackLoading } =
    useFeedback(backToMealDetails)

  let totalAmount = truncateByDecimalPlace(
    feedback.ingredients.reduce(
      (acc, ingredient) => acc + ingredient.amount,
      0,
    ),
  )

  totalAmount = truncateByDecimalPlace(totalAmount, 1)

  let totalCalorie = truncateByDecimalPlace(
    feedback.ingredients.reduce(
      (acc, ingredient) => acc + ingredient.calorie,
      0,
    ),
  )

  totalCalorie = truncateByDecimalPlace(totalCalorie, 1)

  const isAmountError =
    isNaN(totalAmount) || totalAmount === Infinity || totalAmount === 0

  const [servingValid, setServingValid] = useState(true)

  const validateAndUpdateOnServingChange = (portion: IPortion) => {
    const isValid =
      !isNaN(portion.first) &&
      !isNaN(portion.second) &&
      portion.first > 0 &&
      portion.second > 0

    setServingValid(isValid)
    onServingChange(portion)
  }

  const isDisableSubmit = isAmountError || !servingValid || feedback.name === ""

  const [isPopConfirmDiscardOpen, setIsPopConfirmDiscardOpen] = useState(false)

  const decimalServing = truncateByDecimalPlace(
    feedback.portion.first / feedback.portion.second,
  )
  return (
    <Fragment>
      <div style={{ height: 6 }}></div>
      <FlexBox
        onClick={() => {}}
        style={{ height: 44, padding: "9px 10px" }}
        $alignItems="center"
        $justify="space-between"
      >
        <BackButton
          onClick={() => {
            if (isMealChanged && !isDisableSubmit) {
              setIsPopConfirmDiscardOpen(true)
            } else {
              backToMealDetails()
            }
          }}
        >
          <ChevronLeft />
        </BackButton>

        <PageTitle>Edit</PageTitle>
        <div />
      </FlexBox>{" "}
      <MainContent>
        <FlexBox $direction="column" $gap={12} style={{ marginBottom: 24 }}>
          <TextFieldFormItem
            label="Meal"
            value={feedback.name}
            onChange={(value) => onNameChange(value as string)}
          />

          <InputNumberPickerView
            label="Your portion"
            value={decimalServing}
            detailTrailing={feedback.unit}
            onChange={(value) => {
              const { numerator, denominator } = decimalToFraction(value)

              validateAndUpdateOnServingChange({
                first: numerator,
                second: denominator,
              })
            }}
          />

          <InfoSection $bold>
            <InfoItem
              label="Amount"
              value={
                !isNaN(totalAmount)
                  ? getDisplayNumber(totalAmount) + " g"
                  : "Invalid portion"
              }
            />
            <InfoItem
              label="Calories"
              value={getDisplayCalories(totalCalorie) + " cal"}
            />
          </InfoSection>
        </FlexBox>

        <Ingredients
          ingredients={feedback.ingredients}
          onSelectIngredientToEdit={onSelectIngredientToEdit}
          disabled={!servingValid}
        />

        <Button
          style={{ marginTop: 12 }}
          disabled={!servingValid}
          $isOption
          $type="secondary"
          onClick={() => setStep("search-ingredient")}
        >
          <PlusIcon fill="var(--color-navy)" /> Add more ingredients
        </Button>
      </MainContent>
      <BottomCTABox>
        <Button
          loading={sendFeedbackLoading}
          onClick={() => {
            if (isMealChanged) {
              onSubmitFeedback(feedback)
            } else {
              backToMealDetails()
            }
          }}
          disabled={isDisableSubmit}
          style={{ width: "100%" }}
          $type="primary"
        >
          Save update
        </Button>
      </BottomCTABox>
      <PopConfirm
        isOpen={isPopConfirmDiscardOpen}
        title="Unsaved changes?"
        cancelText="Discard"
        confirmText="Save changes"
        onCancel={() => backToMealDetails()}
        onConfirm={() => {
          onSubmitFeedback(feedback)
        }}
        loading={sendFeedbackLoading}
      >
        <div style={{ textAlign: "center" }}>
          Do you want to save the changes?
        </div>
      </PopConfirm>
    </Fragment>
  )
}

export default EditMealGeneral
