import styled from "styled-components"

export const MainContent = styled.div`
  --footer-height: 84px;
  --image-height: 223px;

  display: flex;
  flex-direction: column;
  height: calc(100% - var(--footer-height));
  overflow-y: auto;
  background-color: #fff;
`

export const BottomCTABox = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_xl, 24px)
    var(--spacing_l, 16px);
  width: 100%;
  margin-top: auto;
`
