import styled from "styled-components"

export const StyledIngredientOption = styled.div`
  display: flex;
  padding: var(--spacing_xs, 6px) var(--spacing_m, 12px) 6px
    var(--spacing_l, 16px);
  align-items: center;
  gap: var(--spacing_xxs, 4px);
  border-bottom: 0.5px solid
    var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  justify-content: space-between;
  cursor: pointer;
`

export const IngredientName = styled.div`
  font-size: 16px;
`

export const IngredientAmount = styled.div`
  color: var(--Schemes-On-Surface-Variant, #69757d);
  font-size: 14px;
`

export const IngredientOptionsWrapper = styled.div`
  padding: 4px 8px 0px 8px;
  overflow-y: auto;
  border-radius: var(--spacing_s, 8px);
  height: calc(100% - 80px);
`

export const IngredientOptions = styled.div`
  min-height: 100%;
  border-radius: var(--spacing_s, 8px);
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  display: flex;
  padding: var(--spacing_xs, 6px) 0px;
  flex-direction: column;
  gap: var(--spacing_xs, 6px);
  padding-left: var(--spacing_l, 16px);
  padding-right: var(--spacing_m, 12px);
`
