import {
  MealCalorie,
  MealContent,
  MealImage,
  MealImageBox,
  MealName,
  MealSlice,
  StyledMealItem,
  StyledSwipeAction,
} from "./styled"
import { IMeal } from "features/meal/types"
import { useEffect, useRef } from "react"
import { getDisplayCalories, getDisplayServing } from "utils"
import TrashIcon from "components/Icons/TrashIcon"
import { useDeleteMeal } from "../../hooks"
import { SwipeActionRef } from "antd-mobile"
import { useNavigate } from "react-router-dom"
import { useSetMealDetails } from "features/meal/hooks/mealDetailsHooks"
import mealItemImagePlaceholder from "./MealItemImagePlaceholder.svg"

interface MealItemProps {
  meal: IMeal
  swipedId: string | null
  setSwipedId: (id: string | null) => void
}

const MealItem = (props: MealItemProps) => {
  const { meal, swipedId, setSwipedId } = props
  const { handleDeleteMeal } = useDeleteMeal()
  const setMealDetails = useSetMealDetails()

  const navigate = useNavigate()

  const goToMealDetails = () => {
    setMealDetails(meal)
    navigate(`/meal/${meal.id}/details`)
  }

  useEffect(() => {
    if (swipedId && swipedId !== meal.id) {
      ref.current?.close()
    }
  }, [swipedId])

  const ref = useRef<SwipeActionRef>(null)

  return (
    <StyledSwipeAction
      closeOnTouchOutside={false}
      ref={ref}
      onActionsReveal={() => {
        setSwipedId(meal.id)
      }}
      closeOnAction={false}
      rightActions={[
        {
          key: "delete",
          text: <TrashIcon />,
          color: "danger",
          onClick: () => handleDeleteMeal(meal.id),
        },
      ]}
    >
      <StyledMealItem onClick={goToMealDetails}>
        <MealImageBox>
          <MealImage
            src={meal.imageUrl ? meal.imageUrl : mealItemImagePlaceholder}
            alt="meal-photo"
          />
        </MealImageBox>
        <MealContent>
          <MealName>{meal.name}</MealName>
          <MealSlice>{getDisplayServing(meal.servings)}</MealSlice>
        </MealContent>

        <MealCalorie>{getDisplayCalories(meal.calorie)}</MealCalorie>
      </StyledMealItem>
    </StyledSwipeAction>
  )
}

export default MealItem
