import styled from "styled-components"

export const FoodImageBox = styled.div`
  height: 223px;
  min-height: 223px;
  border: 1.313px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  position: relative;

  .close-preview-btn {
    position: absolute;
    top: 15px;
    right: 16px;
    z-index: 1000;
    cursor: pointer;
  }

  @media only screen and (min-width: 500px) {
    .close-preview-btn {
      position: fixed;
    }
  }
`

export const StyledFoodImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const AIResponse = styled.div`
  position: absolute;
  bottom: 12px;
  left: 16px;
`

export const AIText = styled.div`
  width: 280px;
  padding: var(--spacing_xxs, 4px) var(--spacing_m, 12px);
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  background-color: #fff;
  border-radius: var(--spacing_s, 8px);

  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
`
