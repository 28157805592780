import { Link } from "react-router-dom"
import styled from "styled-components"

export const FoodDetailsMain = styled.div`
  --cta-height: 68px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-left: 0px;
  padding-right: 0px;
  overflow-y: auto;
  height: calc(100% - var(--header-height) - var(--cta-height));
  padding-bottom: 0px;
`

export const FoodDetailsCta = styled.div`
  display: flex;
  padding: var(--spacing_s, 8px) var(--spacing_l, 16px) var(--spacing_m, 12px)
    var(--spacing_l, 16px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
`

export const AddMealMain = styled(FoodDetailsMain)`
  height: calc(100% - var(--header-height));
  padding-top: 0px;
  justify-content: flex-start;
  padding-bottom: 0px;
`

export const StyledRecentMeals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing_xxs, 4px);
  padding: 0 8px;
`

export const StyledRecentMeal = styled.div`
  display: flex;
  padding: 0px var(--spacing_l, 16px) 0px var(--spacing_xs, 6px);
  align-items: center;
  gap: var(--spacing_m, 12px);

  border-radius: var(--spacing_s, 8px);
  border: 0.5px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  width: 100%;
  height: 72px;
`

export const RecentMealImageBox = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  padding: 2px;

  border-radius: var(--spacing_xs, 6px);
  background: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  overflow: hidden;
`

export const RecentMealImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--spacing_xs, 6px);
`

export const RecentMealName = styled.div`
  font-size: 16px;
`

export const RecentMealSlice = styled.div`
  color: var(--Schemes-On-Surface-Variant, #69757d);
  font-size: 14px;
  font-weight: 600;
`

// export const AddMealBtn = styled(HoveredButton)`
//   margin-left: auto;
//   align-self: center;
//   width: 60px;
//   height: 40px;
//   border-radius: 0px;
// `

export const AddMealBtn = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px; /* 100% */
  letter-spacing: 0.14px;
  color: var(--Schemes-Primary, #0da3ba);
  text-align: right;
  margin-left: auto;
  cursor: pointer;
`

export const PageTitle = styled.div`
  font-family: sf-pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 590;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  text-transform: capitalize;
`

export const AddFoodScanBox = styled.div`
  display: flex;
  height: 124px;
  padding: 10px var(--spacing_m, 12px);

  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing_s, 8px);
  align-self: stretch;
  margin-top: auto;

  background: var(--Schemes-Primary-Container-Variant, #cceded);
`

export const AddFoodScanBoxInner = styled.div`
  border-radius: var(--spacing_s, 8px);
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing_xxs, 4px);
`

export const TryFoodScanText = styled.div`
  font-weight: 500;
  text-align: center;
  font-size: 16px;
`

export const RecentMealTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 0 16px;
  margin-bottom: 8px;
`

export const CtaLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  flex: 1 1 0;
`
