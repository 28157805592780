import styled from "styled-components"

export const IngredientTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 140%;
`

export const StyledIngredient = styled.div<{ $disabled?: boolean }>`
  display: flex;
  padding: 15px var(--spacing_l, 16px);
  align-items: center;
  gap: var(--spacing_s, 8px);
  width: 100%;

  border-radius: var(--spacing_s, 8px);

  border: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
`

export const IngredientName = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
`

export const IngredientAmount = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: var(--Schemes-Primary, #0da3ba);
  text-align: right;
  margin-left: auto;
`
