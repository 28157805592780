import { ProgressCircle } from "antd-mobile"
import styled from "styled-components"
import { IDailyProgress } from "../types"
import { numberWithCommas, truncateByDecimalPlace } from "utils"

const KcalNumber = styled.div<{ $isOver: boolean }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  color: ${({ $isOver }) =>
    $isOver
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-On-Primary-Container, #044257)"};
  text-align: center;
`

const KcalDescription = styled.div`
  color: var(--Schemes-On-Surface, #1c1f21);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 19.2px */
`

const getDisplayCalorie = (isOver: boolean, dailyProgress?: IDailyProgress) => {
  if (!dailyProgress) return 0

  let result = 0

  if (isOver) {
    result = dailyProgress.calorie - dailyProgress.goal.caloriePerDay
  } else {
    result = dailyProgress.goal.caloriePerDay - dailyProgress.calorie
  }

  return numberWithCommas(truncateByDecimalPlace(result, 0))
}

const getDisplayPercent = (isOver: boolean, dailyProgress?: IDailyProgress) => {
  if (!dailyProgress) return 0

  const {
    calorie: gainedCalorie,
    goal: { caloriePerDay: goalCalories },
  } = dailyProgress

  let result = 0

  if (isOver) {
    result = (gainedCalorie / goalCalories) * 100
  } else {
    const remainingCalories = goalCalories - gainedCalorie
    result = (remainingCalories / goalCalories) * 100
  }

  if (result > 100) {
    result = 100
  }

  return truncateByDecimalPlace(result, 0)
}

const Progress = ({ dailyProgress }: { dailyProgress?: IDailyProgress }) => {
  const isOver = dailyProgress
    ? dailyProgress.calorie > dailyProgress.goal.caloriePerDay
    : false

  const displayCalorie = getDisplayCalorie(isOver, dailyProgress)

  const fillColor = isOver ? "#FF4921" : "var(--color-primary)"
  const trackColor = isOver ? "#FFC800" : "#00263A24"

  return (
    <ProgressCircle
      percent={getDisplayPercent(isOver, dailyProgress)}
      style={{
        "--track-width": "6px",
        "--size": "124px",
        "--fill-color": fillColor,
        "--track-color": trackColor,
      }}
    >
      <KcalNumber $isOver={isOver}>{displayCalorie}</KcalNumber>
      <KcalDescription>CAL {isOver ? "OVER" : "LEFT"}</KcalDescription>
    </ProgressCircle>
  )
}

export default Progress
