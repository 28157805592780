import { ProgressBar } from "antd-mobile"
import { FlexBox } from "components/Common/FlexBox"
import {  IIngredient } from "features/meal/types"
import styled from "styled-components"
import { getDisplayCalories, getDisplayNumber } from "utils"

const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_l, 16px)
    var(--spacing_l, 16px);
  flex-direction: column;
  gap: var(--spacing_m, 12px);

  border-radius: var(--spacing_s, 8px);
  border: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
`

const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const StyledNutritionItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2px;
`

const NutritionName = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`

const NutritionValue = styled.div<{ $isOver: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;

  color: ${({ $isOver }) =>
    $isOver
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-On-Surface, #1C1F21)"};
`

export interface INutritionItem {
  name: string
  value: string
  color: string
  percent: number
}

const Nutrition = ({ nutrition }: { nutrition: INutritionItem }) => {
  return (
    <StyledNutritionItem>
      <NutritionName>{nutrition.name}</NutritionName>
      <ProgressBar
        percent={nutrition.percent}
        style={{
          "--track-width": "4px",
          "--fill-color": nutrition.color,
          "--track-color": "#00263A24",
        }}
      />
      <NutritionValue $isOver={false}>{nutrition.value}</NutritionValue>
    </StyledNutritionItem>
  )
}

const PackageNutrition = ({
  ingredients,
}: {
  ingredients: IIngredient[]
}) => {
  const { fat, carbohydrate, protein, calorie } = ingredients[0]
  const totalNutritionAmount =
    ingredients[0].fat + ingredients[0].carbohydrate + ingredients[0].protein

  const nutritions = [
    {
      name: "Protein",
      value: `${getDisplayNumber(protein)} g `,
      color: "var(--Accent-2, #F354E4)",
      percent: Math.round((protein / totalNutritionAmount) * 100),
    },
    {
      name: "Fat",
      value: `${getDisplayNumber(fat)} g `,
      color: "var(--Schemes-Secondary, #FFC800)",
      percent: Math.round((fat / totalNutritionAmount) * 100),
    },
    {
      name: "Carbs",
      value: `${getDisplayNumber(carbohydrate)} g `,
      color: "var(--Accent-1, #8948FA)",
      percent: Math.round((carbohydrate / totalNutritionAmount) * 100),
    },
  ]

  return (
    <Wrapper>
      <Title>NUTRITIONAL INFORMATION</Title>
      <div style={{ marginTop: 12 }}>
        <Nutrition
          nutrition={{
            name: "Calories",
            value: getDisplayCalories(calorie) + " cal",
            color: "var(--Schemes-Primary-Container, #4BD9DE)",
            percent: 100,
          }}
        />

        <FlexBox $justify="space-between" $gap={16} style={{ marginTop: 4 }}>
          {nutritions.map((item) => (
            <Nutrition
              key={item.name + item.color}
              nutrition={{
                name: item.name,
                value: item.value,
                color: item.color,
                percent: item.percent,
              }}
            />
          ))}
        </FlexBox>
      </div>
    </Wrapper>
  )
}

export default PackageNutrition
