const PlusCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.68652 20.999C4.68652 11.5788 12.3288 3.93652 21.749 3.93652C31.1692 3.93652 38.8115 11.5788 38.8115 20.999C38.8115 30.4192 31.1692 38.0615 21.749 38.0615C12.3288 38.0615 4.68652 30.4192 4.68652 20.999ZM21.749 6.56152C13.7786 6.56152 7.31152 13.0286 7.31152 20.999C7.31152 28.9695 13.7786 35.4365 21.749 35.4365C29.7195 35.4365 36.1865 28.9695 36.1865 20.999C36.1865 13.0286 29.7195 6.56152 21.749 6.56152ZM21.749 13.124C22.4739 13.124 23.0615 13.7116 23.0615 14.4365V19.6865H28.3115C29.0364 19.6865 29.624 20.2741 29.624 20.999C29.624 21.7239 29.0364 22.3115 28.3115 22.3115H23.0615V27.5615C23.0615 28.2864 22.4739 28.874 21.749 28.874C21.0241 28.874 20.4365 28.2864 20.4365 27.5615V22.3115H15.1865C14.4616 22.3115 13.874 21.7239 13.874 20.999C13.874 20.2741 14.4616 19.6865 15.1865 19.6865H20.4365V14.4365C20.4365 13.7116 21.0241 13.124 21.749 13.124Z"
        fill="#044257"
      />
    </svg>
  )
}

export default PlusCircleIcon
