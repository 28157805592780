import React from "react"

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 18L14 14M6 10.6667C6 11.2795 6.12071 11.8863 6.35523 12.4525C6.58975 13.0187 6.93349 13.5332 7.36683 13.9665C7.80018 14.3998 8.31462 14.7436 8.88081 14.9781C9.447 15.2126 10.0538 15.3333 10.6667 15.3333C11.2795 15.3333 11.8863 15.2126 12.4525 14.9781C13.0187 14.7436 13.5332 14.3998 13.9665 13.9665C14.3998 13.5332 14.7436 13.0187 14.9781 12.4525C15.2126 11.8863 15.3333 11.2795 15.3333 10.6667C15.3333 10.0538 15.2126 9.447 14.9781 8.88081C14.7436 8.31462 14.3998 7.80018 13.9665 7.36683C13.5332 6.93349 13.0187 6.58975 12.4525 6.35523C11.8863 6.12071 11.2795 6 10.6667 6C10.0538 6 9.447 6.12071 8.88081 6.35523C8.31462 6.58975 7.80018 6.93349 7.36683 7.36683C6.93349 7.80018 6.58975 8.31462 6.35523 8.88081C6.12071 9.447 6 10.0538 6 10.6667Z"
        stroke="#00263A"
        strokeOpacity="0.32"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SearchIcon
