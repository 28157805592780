import axios from "axios"
import memoizedRefreshToken from "./refreshToken"
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore"
import dayjs from "dayjs"

const instants = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Timezone-Offset": dayjs().format("Z").split(":")[0],
  },
})

export function setAxiosToken(token: string) {
  instants.defaults.headers.common.Authorization = "Bearer " + token
}

export function removeAxiosToken() {
  instants.defaults.headers.common.Authorization = ""
}

export function setTenant(workspace: string) {
  instants.defaults.headers.common["TENANT"] = workspace
}

const responseBody = (res: any) => res.data
const requester = {
  get: (url: string, params?: any, config = {}) =>
    instants
      .get(url, {
        params,
        ...config,
      })
      .then(responseBody),

  post: (url: string, data: any, config = {}) =>
    instants.post(url, data, config).then(responseBody),
  put: (url: string, data: any, config = {}) =>
    instants.put(url, data, config).then(responseBody),
  delete: (url: string, data?: any) =>
    instants.delete(url, { data }).then(responseBody),
  patch: (url: string, data: any) =>
    instants.patch(url, data).then(responseBody),
}

export const setupInstantsInterceptor = (store: ToolkitStore) => {
  instants.interceptors.response.use(
    (response) => response,
    async (error: any) => {
      const config = error?.config

      if (axios.isCancel(error)) {
        return Promise.reject()
      }

      const isAuthEndpoints = config?.url?.includes("/accounts/token")
      const isAuthError = [401, 403].includes(error?.response?.status)

      if (isAuthError && !config?.sent && !isAuthEndpoints) {
        config.sent = true

        const result = await memoizedRefreshToken(store)

        if (result?.accessToken) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result.accessToken}`,
          }
          setAxiosToken(result.accessToken)
          return axios(config)
        }

        return new Promise(() => {})
      }

      return Promise.reject(error)
    },
  )
}

export default requester
