import requester from "api/requester"
import {
  IDailyOverviewResponse,
  IWeeklyChartDataItem,
  IWeeklyOverviewPayload,
  IWeeklyOverviewResponse,
} from "./types"
import dayjs from "dayjs"
import {
  DATE_PARAM_FORMAT,
  US_DATE_FORMAT_WITHOUT_YEAR,
} from "config"

const overviewAPI = {
  getDailyOverview(
    date: string,
    signal: AbortSignal,
  ): Promise<IDailyOverviewResponse> {
    return requester
      .get("/progresses/daily", { date }, { signal })
      .then((data) => {
        return {
          caloriesPerDay: data.goal.calorie_per_day,
          mealTypeBalance: data.meal_type_balance,
          mostCalories: data.most_calorie,
          gainedCalories: data.calorie,
          mostProtein: data.most_protein,
          mostCarbs: data.most_carb,
          mostFat: data.most_fat,
        }
      })
  },

  getWeeklyOverview(
    { start, end }: IWeeklyOverviewPayload,
    signal: AbortSignal,
  ): Promise<IWeeklyOverviewResponse> {
    return requester
      .get("/progresses/weekly", { from_date: start, to_date: end }, { signal })
      .then((data) => {
        const weeklyChartData = Array.from({ length: 7 }, (_, i) => {
          const dateFormat = dayjs(start)
            .add(i, "day")
            .format(DATE_PARAM_FORMAT)

          const day = data.days_in_week.find(
            ({ date }: { date: string }) => date === dateFormat,
          )
          const dateMealTypeBalanceItem = day?.meal_type_balance
            ? {
                ...day.meal_type_balance,
                goalCalories: day.goal.calorie_per_day,
              }
            : {
                breakfast: 0,
                lunch: 0,
                dinner: 0,
                snack: 0,
                goalCalories: 0,
              }

          const name = dayjs(dateFormat).format(US_DATE_FORMAT_WITHOUT_YEAR)

          return {
            ...dateMealTypeBalanceItem,
            name,
          } as IWeeklyChartDataItem
        })

        return {
          mealTypeBalance: data.meal_type_balance,
          gainedCalories: data.calorie,
          mostCalories: data.most_calorie,
          mostProtein: data.most_protein,
          mostCarbs: data.most_carb,
          mostFat: data.most_fat,
          weeklyChartData,
        }
      })
  },
}

export default overviewAPI
