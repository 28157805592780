import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  DetailsTrailing,
  StyledInput,
  StyledLabel,
  StyledInputNumberFormItem,
  PickerHeader,
  PickerPopupStyle,
} from "./styled"
import { Picker } from "antd-mobile"

type InputSize = "default" | "large"
interface IProps {
  label: string
  value?: number
  placeholder?: string
  detailTrailing?: string
  isError?: boolean
  disabled?: boolean
  onFocus?: () => void
  onChange: (value: number) => void
}

const InputNumberPickerView = (props: IProps) => {
  const {
    detailTrailing,
    isError = false,
    value = "",
    placeholder,
    label,
    onChange,
  } = props

  const [selectedValue, setSelectedValue] = useState(value)

  const columns = useMemo(() => {
    const isValueLestThanTen = Number(selectedValue) < 10
    const isValueLestThanForty = Number(selectedValue) < 40

    const iterator = 40
    const step = isValueLestThanTen ? 0.25 : isValueLestThanForty ? 1 : 5
    const columns = []
    for (let i = 1; i <= iterator; i++) {
      const value = i * step
      columns.push({
        label: value.toString(),
        value,
      })
    }
    return columns
  }, [selectedValue])

  const [localValue, setLocalValue] = useState<number | string>(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const onBlur = () => {
    let nextValue = value as number

    if (nextValue !== localValue) {
      setLocalValue(nextValue)
    }
  }

  const trailingRef = useRef<HTMLDivElement>(null)
  const [trailingWidth, setTrailWidth] = useState(0)

  useLayoutEffect(() => {
    setTrailWidth(trailingRef.current?.getBoundingClientRect().width ?? 0)
  }, [trailingRef, detailTrailing])
  const [visible, setVisible] = useState(false)

  return (
    <Fragment>
      <PickerPopupStyle />
      <Picker
        cancelText=""
        confirmText="Done"
        popupClassName="picker-popup"
        title={<PickerHeader />}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        columns={[columns]}
        onConfirm={(value) => {
          onChange(Number(value[0]))
        }}
        value={[localValue]}
        onSelect={(value) => {
          setSelectedValue(value[0] as number)
        }}
      />
      <StyledInputNumberFormItem
        style={
          {
            "--input-padding-right": "16px",
            "--input-trailing-padding-left": detailTrailing ? "6px" : "0px",
            "--detail-trailing-width": `${trailingWidth}px`,
          } as React.CSSProperties
        }
      >
        {label && <StyledLabel>{label}</StyledLabel>}

        <StyledInput
          readOnly={true}
          onBlur={onBlur}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setVisible(true)
          }}
          onFocus={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          $isError={isError}
          placeholder={placeholder}
          value={localValue}
          onChange={(e) => {
            return
          }}
          style={{
            paddingRight:
              "calc(var(--input-padding-right) + var(--input-trailing-padding-left) + var(--detail-trailing-width))",
          }}
        />
        {detailTrailing && <DetailsTrailing>{detailTrailing}</DetailsTrailing>}

        {detailTrailing && (
          <DetailsTrailing style={{ visibility: "hidden" }} ref={trailingRef}>
            {detailTrailing}
          </DetailsTrailing>
        )}
      </StyledInputNumberFormItem>
    </Fragment>
  )
}

export default InputNumberPickerView
