import Button from "components/Buttons/Button"
import { FlexBox } from "components/Common/FlexBox"
import SmallChevronRight from "components/Icons/SmallChevronRight"
import { useLogOut } from "features/auth/hooks"
import { useSetPageTitle } from "libs/ga"
import { Link, useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import styled from "styled-components"

const Wrapper = styled.div`
  height: calc(100% - var(--footer-height));
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-left: 8px;
  padding-right: 8px;
`
export const ProfilePageTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
`

const ProfileOption = styled.div`
  border-radius: var(--spacing_s, 8px);
  border: 0.5px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);

  display: flex;
  padding: 12px var(--spacing_l, 16px);
  gap: var(--spacing_l, 16px);
  justify-content: space-between;
  cursor: pointer;
`

const ProfileOptionName = styled.div`
  font-size: 16px;
`

const Profile = () => {
  useSetPageTitle("Profile")
  const { handleLogout, logOutLoading } = useLogOut()
  const navigate = useNavigate()

  return (
    <Wrapper>
      <FlexBox
        style={{ padding: "8px 6px", marginBottom: 12 }}
        $justify="space-between"
        $alignItems="center"
        $gap={12}
      >
        <ProfilePageTitle>Profile</ProfilePageTitle>
      </FlexBox>

      <FlexBox $direction="column" $gap={12}>
        <ProfileOption
          onClick={() => {
            if (!logOutLoading) {
              navigate(PATHS.app.metric)
            }
          }}
        >
          <ProfileOptionName>Body metrics</ProfileOptionName>
          <SmallChevronRight fill="#69757D" />
        </ProfileOption>
        
        <ProfileOption
          onClick={() => {
            if (!logOutLoading) {
              navigate(PATHS.app.yourGoal)
            }
          }}
        >
          <ProfileOptionName>Your Goal</ProfileOptionName>
          <SmallChevronRight fill="#69757D" />
        </ProfileOption>
        <Button
          onClick={handleLogout}
          loading={logOutLoading}
          style={{ marginTop: 54 }}
          $type="secondary"
        >
          Log Out
        </Button>
      </FlexBox>
    </Wrapper>
  )
}

export default Profile
