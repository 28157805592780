import { FlexBox } from "components/Common/FlexBox"
import { US_DATE_FORMAT_WITH_YEAR } from "config"
import dayjs from "dayjs"
import styled from "styled-components"
import { getDisplayCalories } from "utils"

const StyledTooltip = styled.div`
  padding: 8px var(--spacing_m, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing_xxs, 4px);

  border-radius: var(--border-radius, 8px);
  background: var(--White, #fff);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
  width: 142px;
`
const TooltipTitle = styled.div`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: center;
`
const TooltipCal = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 160%;
  text-align: center;
`

const MealTypeColor = styled.div`
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  margin-right: 8px;
`

const MealTypeName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  text-transform: capitalize;
`

const MealTypeCal = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 160%;
  text-align: right;
  margin-left: auto;
`

const MealType = ({
  mealType,
}: {
  mealType: { color: string; name: string; value: number }
}) => {
  const { color, name, value } = mealType
  return (
    <FlexBox $alignItems="center">
      <MealTypeColor style={{ background: color }} />
      <MealTypeName>{name}</MealTypeName>

      <MealTypeCal>{getDisplayCalories(value)} cal</MealTypeCal>
    </FlexBox>
  )
}
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const cal = payload.reduce((acc: number, cur: any) => acc + cur.value, 0)
    return (
      <StyledTooltip>
        <TooltipTitle>{dayjs().format(US_DATE_FORMAT_WITH_YEAR)}</TooltipTitle>
        <TooltipCal>{getDisplayCalories(cal)} cal</TooltipCal>

        <FlexBox $direction="column" $gap={4} style={{ marginTop: 4 }}>
          {payload.map((mealType: any) => (
            <MealType mealType={mealType} key={mealType.name} />
          ))}
        </FlexBox>
      </StyledTooltip>
    )
  }

  return null
}

export default CustomTooltip
