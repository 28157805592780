import {
  IEditMealStep,
  IFeedback,
  IIngredient,
  IPortion,
} from "features/meal/types"
import { useState } from "react"
import { useCreateMeal } from "../../hooks/hooks"
import styled from "styled-components"
import { FlexBox } from "components/Common/FlexBox"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import { CtaBox, MainWrapper, PageTitle } from "./styled"
import Button from "components/Buttons/Button"
import ImageUploader from "./ImageUploader"
import TextFieldFormItem from "components/TextFieldFormItem"
import InfoSection from "components/Common/InfoSection"
import InfoItem from "components/InfoItem"
import Ingredients from "features/meal/EditMeal/General/Ingredients"
import PlusIcon from "components/Icons/PlusIcon"
import {
  decimalToFraction,
  getDisplayCalories,
  getDisplayNumber,
  truncateByDecimalPlace,
} from "utils"
import { isPortionValid } from "features/meal/mealHelper"
import ManualPortionPicker from "./ManualPortionPicker"

const Wrapper = styled.div`
  height: 100%;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

const ManualAddMealMain = ({
  feedback,
  onSelectIngredientToEdit,
  onServingChange,
  onNameChange,
  setStep,
  onBack,
  onUnitChange,
}: {
  feedback: IFeedback
  onSelectIngredientToEdit: (ingredient: IIngredient) => void
  onServingChange: (portion: IPortion) => void
  onNameChange: (value: string) => void
  setStep: (step: IEditMealStep) => void
  onBack: () => void
  onUnitChange: (unit: string) => void
}) => {
  const { handleCreateMeal, createMealLoading } = useCreateMeal()

  const onSubmit = () => {
    handleCreateMeal({
      manual: true,
      name: feedback.name,
      ingredients: feedback.ingredients,
      servings: feedback.portion.first + "/" + feedback.portion.second,
      unit: feedback.unit,
    })
  }
  let totalAmount = truncateByDecimalPlace(
    feedback.ingredients.reduce(
      (acc, ingredient) => acc + ingredient.amount,
      0,
    ),
  )

  totalAmount = truncateByDecimalPlace(totalAmount, 1)

  const totalCalories = feedback.ingredients.reduce(
    (acc, ingredient) => acc + ingredient.calorie,
    0,
  )

  const portion = feedback.portion
  const isServingsValid = isPortionValid(portion)

  const [isNameTouched, setIsNameTouched] = useState(false)
  const isDisabledSubmit =
    !feedback.name ||
    !isServingsValid ||
    createMealLoading ||
    feedback.ingredients.length === 0

  const decimalServing = truncateByDecimalPlace(
    feedback.portion.first / feedback.portion.second,
  )

  return (
    <Wrapper>
      <div style={{ height: 6 }}></div>
      <FlexBox
        style={{ height: 44, padding: "9px 12px" }}
        $justify="space-between"
        $alignItems="center"
      >
        <BackButton
          onClick={() => {
            if (!createMealLoading) {
              onBack()
            }
          }}
        >
          <ChevronLeft />
        </BackButton>

        <PageTitle>Add new meal</PageTitle>
        <div></div>
      </FlexBox>

      <MainWrapper>
        <ImageUploader />

        <FlexBox $direction="column" $gap={12}>
          <TextFieldFormItem
            disabled={createMealLoading}
            setIsTouched={() => {
              if (!isNameTouched) {
                setIsNameTouched(true)
              }
            }}
            label="Meal"
            onChange={(value) => onNameChange(value as string)}
            value={feedback.name}
            placeholder="Name your meal"
            isError={isNameTouched && !feedback.name}
          />

          <ManualPortionPicker
            unit={feedback.unit}
            portion={decimalServing}
            onChange={(portion, unit) => {
              const { numerator, denominator } = decimalToFraction(portion)

              onServingChange({
                first: numerator,
                second: denominator,
              })
              onUnitChange(unit)
            }}
          />

          <InfoSection $bold style={{ padding: 0 }}>
            <InfoItem
              label="Amount"
              value={
                !isNaN(totalAmount)
                  ? getDisplayNumber(totalAmount) + " g"
                  : "Invalid portion"
              }
            />
            <InfoItem
              label="Calories"
              value={getDisplayCalories(totalCalories) + " cal"}
            />
          </InfoSection>
        </FlexBox>

        <FlexBox $direction="column" $gap={12}>
          <Ingredients
            disabled={!isServingsValid}
            ingredients={feedback.ingredients}
            onSelectIngredientToEdit={(ingredient) => {
              if (!createMealLoading && isServingsValid) {
                onSelectIngredientToEdit(ingredient)
              }
            }}
          />

          <Button
            disabled={createMealLoading || !isServingsValid}
            $isOption
            $type="secondary"
            onClick={() => {
              if (isServingsValid) {
                setStep("search-ingredient")
              }
            }}
          >
            <PlusIcon fill="var(--color-navy)" /> Add more ingredients
          </Button>
        </FlexBox>
      </MainWrapper>
      <CtaBox>
        <Button
          $type="primary"
          loading={createMealLoading}
          disabled={isDisabledSubmit}
          onClick={() => onSubmit()}
        >
          Add meal
        </Button>
      </CtaBox>
    </Wrapper>
  )
}

export default ManualAddMealMain
