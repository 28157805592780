import styled, { css } from "styled-components"

const StyledInfoItem = styled.div<{ $hasBorder?: boolean }>`
  padding: 11px 16px;
  padding-left: 0;
  margin-left: 16px;
  display: flex;
  justify-content: space-between;

  &:first-child {
    border-bottom: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14));
  }
  ${(props) =>
    props.$hasBorder &&
    css`
      border-bottom: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14));
    `}
`

const InfoItemLabel = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  height: 22px;

  overflow: hidden;
  color: var(--Schemes-On-Surface-Variant, #69757d);
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
`

const InfoItemValue = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: right;
`

const InfoItem = ({
  label,
  value,
  hasBorder = false,
}: {
  label: string
  value: string
  hasBorder?: boolean
}) => {
  return (
    <StyledInfoItem $hasBorder={hasBorder}>
      <InfoItemLabel>{label}</InfoItemLabel>
      <InfoItemValue>{value}</InfoItemValue>
    </StyledInfoItem>
  )
}

export default InfoItem
