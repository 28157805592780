import React from "react"

const ChatFormIcon = () => {
  return (
    <svg
      style={{ position: "absolute", left: 41, bottom: 12 }}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="7"
      viewBox="0 0 17 7"
      fill="none"
    >
      <path
        d="M0.125388 4.05998C5.34177 6.4322 11.538 6.17237 13.9841 5.74592L16.6864 3.03153C14.8947 2.71363 11.2566 1.80054 11.0381 0.691333C7.76234 3.93836 1.57118 4.15243 0.125388 4.05998Z"
        fill="white"
      />
    </svg>
  )
}

export default ChatFormIcon
