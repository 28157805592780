import { Fragment, useMemo } from "react"
import ManualAddMealMain from "./ManualAddMealMain"
import {
  useBackToHomeAfterCreateManualMeal,
  useIngredientOptions,
  useResetMealState,
  useSetMealTypeParams,
} from "../hooks/hooks"
import { useUpdateFeedback } from "../hooks/mealDetailsHooks"
import EditIngredient from "../EditMeal/EditIngredient"
import SearchIngredient from "../EditMeal/SearchIngredient"
import AddIngredient from "../EditMeal/AddIngredient"
import { IMeal } from "../types"
import { IMealType } from "features/home/types"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { useSetPageTitle } from "libs/ga"

const newMeal: IMeal = {
  foodType: "food",
  imageUrl: "",
  calorie: 0,
  type: "BREAKFAST",
  status: "active",
  id: "1",
  name: "",
  servings: "1/1",
  ingredients: [],
  pfc: {
    protein: 0,
    fat: 0,
    carb: 0,
  },
  unit: "unit",
  comment: "",
}

const ManualAdd = () => {
  useSetPageTitle("Manual Add Meal")
  useIngredientOptions()
  useBackToHomeAfterCreateManualMeal()
  useResetMealState()

  const mealType = useSetMealTypeParams()

  const initMeal = useMemo(() => {
    return {
      ...newMeal,
      type: mealType as IMealType,
    }
  }, [])

  const {
    feedback,
    step,
    setStep,

    onNameChange,
    onPortionChange,

    addingIngredient,
    onStartAddingIngredient,
    onAddingIngredientAmountChange,
    onFinishAdding,

    editingIngredient,
    onSelectIngredientToEdit,
    onEditingIngredientAmountChange,
    onFinishEditing,

    onDeleteIngredient,
    onUnitChange
  } = useUpdateFeedback(initMeal)

  const navigate = useNavigate()
  const onBack = () => {
    navigate(PATHS.app.meal.recent + "?mealType=" + mealType)
  }

  return (
    <Fragment>
      {step === "edit" && feedback && (
        <ManualAddMealMain
          onSelectIngredientToEdit={onSelectIngredientToEdit}
          feedback={feedback}
          onNameChange={onNameChange}
          onServingChange={onPortionChange}
          setStep={setStep}
          onBack={onBack}
          onUnitChange={onUnitChange}
        />
      )}

      {step === "edit-ingredient" && editingIngredient && (
        <EditIngredient
          editingIngredient={editingIngredient}
          onIngredientAmountChange={onEditingIngredientAmountChange}
          onFinishEditing={onFinishEditing}
          onDeleteIngredient={() => onDeleteIngredient()}
        />
      )}

      {step === "search-ingredient" && feedback && (
        <SearchIngredient
          onStartAddingIngredient={onStartAddingIngredient}
          ingredients={feedback?.ingredients}
          setStep={setStep}
        />
      )}

      {step === "add-ingredient" && addingIngredient && (
        <AddIngredient
          addingIngredient={addingIngredient}
          onFinishAdding={onFinishAdding}
          onAddingIngredientAmountChange={onAddingIngredientAmountChange}
          setStep={setStep}
        />
      )}
    </Fragment>
  )
}

export default ManualAdd
