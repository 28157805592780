import { Fragment, useState } from "react"
import { FlexBox } from "components/Common/FlexBox"
import TextFieldFormItem from "components/TextFieldFormItem"
import { BottomCTABox, MainContent } from "./styled"
import InfoSection from "components/Common/InfoSection"
import Ingredients from "./Ingredients"
import Button from "components/Buttons/Button"
import {
  IFeedback,
  IIngredient,
  IPortion,
  ILogMealStep,
} from "features/meal/types"
import PlusIcon from "components/Icons/PlusIcon"
import InfoItem from "components/InfoItem"
import { ImageViewer } from "antd-mobile"
import {
  decimalToFraction,
  getDisplayCalories,
  getDisplayNumber,
  truncateByDecimalPlace,
} from "utils"
import FoodImage from "./FoodImage"
import InputNumberPicker from "components/InputNumberPicker"
import PackageNutrition from "components/Chat/PackageNutrition"
import { PACKAGE_TYPE } from "config"

const EditMealGeneral = ({
  feedback,
  onSelectIngredientToEdit,
  onSave,
  onServingChange,
  onNameChange,
  setStep,
  sendFeedbackLoading,
}: {
  feedback: IFeedback
  onSelectIngredientToEdit: (ingredient: IIngredient) => void
  onSave: () => void
  onServingChange: (portion: IPortion) => void
  onNameChange: (value: string) => void
  setStep: (step: ILogMealStep) => void
  sendFeedbackLoading: boolean
}) => {
  let totalAmount = feedback.ingredients.reduce(
    (acc, ingredient) => acc + ingredient.amount,
    0,
  )

  totalAmount = truncateByDecimalPlace(totalAmount, 1)

  let totalCalorie = feedback.ingredients.reduce(
    (acc, ingredient) => acc + ingredient.calorie,
    0,
  )

  totalCalorie = truncateByDecimalPlace(totalCalorie, 1)

  const isServingError =
    isNaN(totalAmount) || totalAmount === Infinity || totalAmount === 0

  const validateAndUpdateOnServingChange = (value: number) => {
    const { numerator, denominator } = decimalToFraction(value)

    onServingChange({
      first: numerator,
      second: denominator,
    })
  }

  const decimalServing = truncateByDecimalPlace(
    feedback.portion.first / feedback.portion.second,
  )
  const isDisableAction = sendFeedbackLoading
  const isFormError = isServingError || !feedback.name

  const [imagePreviewerVisible, setImagePreviewerVisible] = useState(false)
  const isPackage = feedback.foodType === PACKAGE_TYPE
  const previewImageUrl = feedback.previewImageUrl ?? feedback.imageUrl

  return (
    <Fragment>
      <MainContent>
        <ImageViewer
          image={previewImageUrl}
          visible={imagePreviewerVisible}
          onClose={() => {
            setImagePreviewerVisible(false)
          }}
        />
        <FoodImage
          isPreviewing={imagePreviewerVisible}
          setImagePreviewerVisible={setImagePreviewerVisible}
          imageUrl={previewImageUrl}
          response={feedback.comment}
        />

        <div style={{ padding: 16 }}>
          <FlexBox $direction="column" $gap={isPackage ? 24 : 12}>
            <TextFieldFormItem
              label="Meal"
              value={feedback.name}
              onChange={(value) => onNameChange(value as string)}
            />

            <InputNumberPicker
              label="Your portion"
              value={decimalServing}
              detailTrailing={feedback.unit}
              onChange={(value) => {
                validateAndUpdateOnServingChange(value)
              }}
            />

            {!isPackage && (
              <InfoSection $bold style={{ padding: 0 }}>
                <InfoItem
                  label="Amount"
                  value={
                    !isNaN(totalAmount)
                      ? getDisplayNumber(totalAmount) + " g"
                      : "Invalid portion"
                  }
                />
                <InfoItem
                  label="Calories"
                  value={getDisplayCalories(totalCalorie) + " cal"}
                />
              </InfoSection>
            )}
          </FlexBox>
          {isPackage ? (
            <div style={{ marginTop: 24 }}>
              <PackageNutrition ingredients={feedback.ingredients} />
            </div>
          ) : (
            <Fragment>
              <Ingredients
                ingredients={feedback.ingredients}
                onSelectIngredientToEdit={onSelectIngredientToEdit}
                disabled={isDisableAction}
              />

              <Button
                style={{ marginTop: 12, width: "100%" }}
                $isOption
                $type="secondary"
                onClick={() => setStep("search-ingredient")}
                disabled={isDisableAction}
              >
                <PlusIcon fill="var(--color-navy)" /> Add more ingredients
              </Button>
            </Fragment>
          )}
        </div>
      </MainContent>

      <BottomCTABox style={{ background: "#fff" }}>
        <Button
          loading={sendFeedbackLoading}
          onClick={onSave}
          disabled={isFormError || isDisableAction}
          style={{ width: "100%" }}
          $type="primary"
        >
          Add meal
        </Button>
      </BottomCTABox>
    </Fragment>
  )
}

export default EditMealGeneral
