import styled from "styled-components"

export const FoodDetailsCta = styled.div`
  display: flex;
  padding: var(--spacing_s, 8px) var(--spacing_l, 16px) var(--spacing_m, 12px)
    var(--spacing_l, 16px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
`

export const FoodDetailsMain = styled.div`
  --cta-height: 68px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-left: 0px;
  padding-right: 0px;
  overflow-y: auto;
  height: calc(100% - var(--header-height) - var(--cta-height));
  padding-bottom: 0px;
`

export const PageTitle = styled.div`
  font-family: sf-pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 590;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  text-transform: capitalize;
`
