import { PieChart, Pie, Cell } from "recharts"
import { IMealTypePercent } from "../types"

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x =
    percent === 100 ? outerRadius : cx + radius * Math.cos(-midAngle * RADIAN)
  const y =
    percent === 100 ? outerRadius : cy + radius * Math.sin(-midAngle * RADIAN)

  const shouldDisplay = percent >= 8

  return (
    shouldDisplay && (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "22px",
          fontFamily: "Helvetica Neue",
          fill: [0, 1].includes(index) ? "#fff" : "#1C1F21",
        }}
      >
        {`${percent}%`}
      </text>
    )
  )
}

const DailyPieChart = ({ data }: { data: IMealTypePercent }) => {

  const cleanData = data

    .filter((item) => item.value > 0)
    .map((item) => {
      return {
        value: item.value,
        color: item.color,
        name: item.name,
        percent: item.percent,
      }
    })
  return (
    <PieChart width={204} height={204}>
      <Pie
        stroke=""
        blendStroke={false}
        legendType="none"
        startAngle={90}
        endAngle={-270}
        data={cleanData}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={100}
        fill="#8884d8"
        dataKey="value"
      >
        {cleanData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  )
}

export default DailyPieChart
