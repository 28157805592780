import {
  forwardRef,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import MessageItem from "components/Chat/MessageItem"
import {
  ChatContentBox,
  ChatInputBox,
  StyledScannedPhoto,
  Wrapper,
} from "./Styled"
import {
  useCreateMeal,
  useHomePathWithSelectedDate,
  useProcessImage,
  useResetMealState,
  useSetMealTypeParams,
  useUploadImage,
} from "../hooks/hooks"
import { useAppSelector } from "app/hooks"
import styled from "styled-components"
import ChatInput from "components/Chat/ChatInput"
import AppLoading from "components/AppLoading"
import { useSetPageTitle } from "libs/ga"
import { Link, useNavigate } from "react-router-dom"
import { FlexBox } from "components/Common/FlexBox"
import CloseIcon from "components/Icons/CloseIcon"
import Feedback from "./Feedback"
import {
  useCheckShouldShowTutorial,
  useIsTutorialShowed,
} from "features/onboarding/hooks"
import PATHS from "router/paths"
import { USER_UPLOADED_IMAGE_PREFIX } from "config"
import { ReviewPhoto } from "components/Chat/MessageItem/styled"
import CreateMealLoader from "./CreateMealLoader"
import { getUserUploadedImageUrl } from "utils"
import NotFound from "./NotFound"
import { useDailyScanProgress } from "../hooks/scanMealHooks"
import { SpinLoading } from "antd-mobile"

const UploadedImage = styled.img`
  border-radius: 24px;
  border: 6px solid var(--Schemes-Primary-Container-Variant-2, #0fbdd4);
  object-fit: cover;

  width: 100%;
  height: 100%;
`

const UploadedPhotoWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0px;
  width: 100%;
  padding: 24px;
  padding-bottom: 0px;
  background-color: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

const UploadedPhoto = ({ src }: { src: string }) => {
  const ref = useRef<HTMLImageElement>(null)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().width)
    }
  }, [ref])
  return (
    <UploadedPhotoWrapper
      ref={ref}
      style={{
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 6,
      }}
    >
      <UploadedImage src={src} />
    </UploadedPhotoWrapper>
  )
}

interface IScanedPhotoProps {
  imageUrl: string
}

const ScannedPhoto = forwardRef<HTMLDivElement, IScanedPhotoProps>(
  ({ imageUrl }, ref) => {
    return (
      <StyledScannedPhoto ref={ref}>
        <ReviewPhoto src={imageUrl} />
        <div className="scan-to-bottom"></div>
        <div className="scan-to-top"></div>
      </StyledScannedPhoto>
    )
  },
)

const ScanMeal = () => {
  useSetPageTitle("Food Detector")

  const mealType = useSetMealTypeParams()
  useResetMealState()

  const navigate = useNavigate()
  const homePath = useHomePathWithSelectedDate()

  const { isTutorialShowed } = useIsTutorialShowed()
  const {
    isShowTutorial,
    checkShowTutorialLoading,
    isRevalidatedShouldShowTutorial,
  } = useCheckShouldShowTutorial()

  useEffect(() => {
    const shouldRedirectToTutorial =
      !isTutorialShowed &&
      !checkShowTutorialLoading &&
      isRevalidatedShouldShowTutorial &&
      isShowTutorial === true

    if (shouldRedirectToTutorial) {
      navigate(`${PATHS.app.meal.tutorial}?mealType=${mealType}`)
    }
  }, [
    isShowTutorial,
    checkShowTutorialLoading,
    isRevalidatedShouldShowTutorial,
  ])

  const messages = useAppSelector((state) => state.meal.messages)
  const sendFeedbackLoading = useAppSelector(
    (state) => state.meal.sendFeedbackLoading,
  )
  const step = useAppSelector((state) => state.meal.step)
  const { imageBase64Encoded } = useUploadImage()
  const ref = useRef<HTMLDivElement>(null)

  const { getDailyScanProgressLoading, isScanDisabled } =
    useDailyScanProgress(step)

  useEffect(() => {
    const scrollToTheBottom = () => {
      const scrollEl = ref.current
      scrollEl?.scroll({
        top: scrollEl?.scrollHeight,
        behavior: "smooth",
      })
    }
    scrollToTheBottom()
  }, [messages])

  const { processImageLoading, imageUrl, displayImageType } = useProcessImage()

  const isFeedBack = [
    "edit",
    "edit-ingredient",
    "search-ingredient",
    "add-ingredient",
  ].includes(step)

  const cannedPhotoRef = useRef<HTMLDivElement>(null)

  const mealImageMessage = messages.find(
    (message) =>
      message.texts.length === 1 &&
      message.texts[0].includes(USER_UPLOADED_IMAGE_PREFIX),
  )

  const userUploadedImageUrl = mealImageMessage
    ? getUserUploadedImageUrl(mealImageMessage.texts[0])
    : ""

  const [scannedPhotoHeight, setScannedPhotoHeight] = useState(350)

  useEffect(() => {
    if (userUploadedImageUrl) {
      setScannedPhotoHeight(cannedPhotoRef.current?.clientHeight ?? 350)
    }
  }, [userUploadedImageUrl])
  const { createMealLoading } = useCreateMeal()

  const goToHome = () => {
    if (!createMealLoading) {
      navigate(homePath)
    }
  }

  return (
    <Wrapper>
      {step === "not-found" ? (
        <NotFound imageUrl={userUploadedImageUrl} />
      ) : isFeedBack ? (
        <Feedback />
      ) : (
        <Fragment>
          <FlexBox
            style={{ height: 44, padding: "9px 4px" }}
            $alignItems="center"
          >
            <CloseIcon
              onClick={goToHome}
              style={{
                cursor: createMealLoading ? "not-allowed" : "pointer",
                opacity: createMealLoading ? 0.5 : 1,
              }}
            />
          </FlexBox>{" "}
          {userUploadedImageUrl && (
            <ScannedPhoto
              imageUrl={userUploadedImageUrl}
              ref={cannedPhotoRef}
            />
          )}
          <ChatContentBox
            ref={ref}
            style={
              getDailyScanProgressLoading
                ? {
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }
                : {}
            }
          >
            {getDailyScanProgressLoading ? (
              <SpinLoading />
            ) : (
              <Fragment>
                {messages.map((message, index) => (
                  <MessageItem key={index} message={message} />
                ))}
              </Fragment>
            )}
          </ChatContentBox>
          {displayImageType === "base64" && imageBase64Encoded && (
            <UploadedPhoto src={imageBase64Encoded} />
          )}
          {displayImageType === "url" && imageUrl && (
            <UploadedPhoto src={imageUrl} />
          )}
          <ChatInputBox>
            <ChatInput disableScan={isScanDisabled} />
          </ChatInputBox>
          <div style={{ fontWeight: 900, height: 0, overflow: "hidden" }}>
            For preload font bold
          </div>
          {processImageLoading && <AppLoading content="Processing image" />}
          {step === "loading" && userUploadedImageUrl && (
            <CreateMealLoader scannedPhotoHeight={scannedPhotoHeight} />
          )}
          {sendFeedbackLoading && <AppLoading content="Sending feedback" />}
        </Fragment>
      )}
    </Wrapper>
  )
}

export default ScanMeal
