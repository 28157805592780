import styled from "styled-components"

export const StyledSearchInput = styled.div`
  --icon-width: 24px;
  --icon-margin-right: 8px;

  display: flex;
  position: relative;
  width: 100%;
`
export const StyledIconBox = styled.label`
  position: absolute;
  text-align: left;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`

export const StyledInput = styled.input`
  padding: 8px 7px;
  padding-left: calc(
    var(--icon-width) + var(--icon-margin-right) + var(--icon-margin-right)
  );
  line-height: 26px;
  font-weight: 500;
  border-radius: 8px;
  border-radius: 10px;
  background: var(--Schemes-Surface-Container, #ebedf0);
  border: none;
  color: var(--color-dark);

  width: 100%;
  height: 100%;
  font-size: 16px;

  &,
  &:focus,
  &:active,
  &:focus-visible {
    outline: none;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--Schemes-Outline, rgba(0, 38, 58, 0.32));
    font-family: proxima-nova;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--Schemes-Outline, rgba(0, 38, 58, 0.32));
    font-family: proxima-nova;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

export const DetailsTrailing = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--color-navy);
`
