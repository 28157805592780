import { IMeal } from "features/meal/types"
import { useSetMealTypeParams } from "../hooks/hooks"
import styled from "styled-components"
import { FlexBox } from "components/Common/FlexBox"
import ChevronLeft from "components/Icons/ChevronLeft"
import { useRecentMeals } from "features/home/hooks"
import { NoMealsBox } from "features/home/styled"
import {
  RecentMealImage,
  RecentMealImageBox,
  StyledRecentMeals,
  StyledRecentMeal,
  RecentMealSlice,
  RecentMealName,
  AddMealBtn,
  PageTitle,
  AddFoodScanBox,
  AddFoodScanBoxInner,
  AddMealMain,
  TryFoodScanText,
  RecentMealTitle,
  CtaLink,
} from "./styled"
import { Link, useNavigate } from "react-router-dom"
import ScanMealIcon from "components/Icons/ScanMealIcon"
import PATHS from "router/paths"
import { useHomePathWithSelectedDate } from "features/meal/hooks/hooks"
import { BackButton } from "features/meal/ScanMeal/Feedback/styled"
import PlusCircleIcon from "components/Icons/PlusCircleIcon"
import { useSetMealDetails } from "../hooks/mealDetailsHooks"
import { useSetPageTitle } from "libs/ga"
import mealImageItemPlaceholder from "../../../assets/icons/MealItemImagePlaceholder.svg"
import { useCheckShouldShowTutorial } from "features/onboarding/hooks"
import { getDisplayServing } from "utils"
import { useLogBrazeEvent } from "libs/braze"

const RecentMeal = ({
  meal,
  onClick,
}: {
  meal: IMeal
  onClick: () => void
}) => {
  return (
    <StyledRecentMeal onClick={onClick}>
      <RecentMealImageBox>
        <RecentMealImage src={meal.imageUrl ?? mealImageItemPlaceholder} />
      </RecentMealImageBox>
      <div>
        <RecentMealName>{meal.name}</RecentMealName>
        <RecentMealSlice>{getDisplayServing(meal.servings)}</RecentMealSlice>
      </div>

      <AddMealBtn>Add</AddMealBtn>
    </StyledRecentMeal>
  )
}

const Wrapper = styled.div`
  height: 100%;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
  --footer-height: 68px;
`

const RecentMeals = () => {
  useSetPageTitle("Recent Meals")
  const { logBrazeManualInput, logBrazeTryAI } = useLogBrazeEvent()
  const { recentMeals, isNoRecentMeals } = useRecentMeals()
  const { isShowTutorial, checkShowTutorialLoading } =
    useCheckShouldShowTutorial()

  const homePath = useHomePathWithSelectedDate()
  const mealType = useSetMealTypeParams()
  const setMealDetails = useSetMealDetails()

  const navigate = useNavigate()

  const onSelectMeal = (meal: IMeal) => {
    setMealDetails(meal)
    navigate(`/meal/${meal.id}/add-recent?mealType=${mealType}`)
  }

  const onTryAI = () => {
    if (checkShowTutorialLoading) {
      return
    }

    logBrazeTryAI()
    if (isShowTutorial) {
      navigate(`${PATHS.app.meal.tutorial}?mealType=${mealType}`)
    } else {
      navigate(`${PATHS.app.meal.scan}?mealType=${mealType}`)
    }
  }

  return (
    <Wrapper>
      {" "}
      <FlexBox
        style={{ height: 44, padding: "9px 12px" }}
        $justify="space-between"
        $alignItems="center"
      >
        <Link to={homePath}>
          <BackButton>
            <ChevronLeft />
          </BackButton>
        </Link>

        <PageTitle>{mealType}</PageTitle>
        <div></div>
      </FlexBox>
      <AddMealMain>
        <RecentMealTitle>Most Recent</RecentMealTitle>{" "}
        {isNoRecentMeals ? (
          <NoMealsBox style={{ margin: "0px 12px" }}>
            {" "}
            There’s no meal yet. Let’s log your first meal.
          </NoMealsBox>
        ) : (
          <StyledRecentMeals>
            {recentMeals.map((meal) => (
              <RecentMeal
                meal={meal}
                key={meal.id}
                onClick={() => onSelectMeal(meal)}
              ></RecentMeal>
            ))}
          </StyledRecentMeals>
        )}
        <AddFoodScanBox>
          <div
            style={{
              flex: "1 1 0",
              cursor: checkShowTutorialLoading ? "progress" : "pointer",
            }}
            onClick={onTryAI}
          >
            <AddFoodScanBoxInner>
              <ScanMealIcon />
              <TryFoodScanText> Try AI Food Scan</TryFoodScanText>
            </AddFoodScanBoxInner>
          </div>

          <CtaLink
            to={`${PATHS.app.meal.manualAdd}?mealType=${mealType}`}
            onClick={() => {
              logBrazeManualInput()
            }}
          >
            <AddFoodScanBoxInner>
              <PlusCircleIcon />
              <TryFoodScanText> Manual Input</TryFoodScanText>
            </AddFoodScanBoxInner>
          </CtaLink>
        </AddFoodScanBox>
      </AddMealMain>
    </Wrapper>
  )
}

export default RecentMeals
