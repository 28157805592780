import { MessageItem } from "./features/meal/types"
import { createAction } from "@reduxjs/toolkit"

export const NEXT_LINE = ":::NEXT_LINE:::"

export const FOOD_NAME_PREFIX = "FOOD_NAME:"
export const INGREDIENTS_PREFIX = "INGREDIENTS:"
export const INGREDIENT_ITEM_PREFIX = "INGREDIENT_ITEM:"
export const USER_UPLOADED_IMAGE_PREFIX = "USER_UPLOADED_IMAGE:"

export const WELCOME_MESSAGE =
  "Upload a photo of your meal and we'll calculate its ingredients and nutrients for you! 🥗🔥🥑."

export const NOT_A_GOOD_MESSAGE = "not a picture of food"

export const NOT_A_MEAL_MESSAGE: MessageItem = {
  owner: "kagami",
  texts: [
    "I couldn’t find any meals in the photo 😔.",
    "Please send another one with any meal in it or with clearer photo.",
  ],
}

export const NETWORK_ERROR_MESSAGE: MessageItem = {
  owner: "kagami",
  texts: ["Something went wrong😔.", "Please upload a photo and try again."],
}

export const OUT_OF_DAILY_SCAN_MESSAGE: MessageItem = {
  owner: "kagami",
  texts: [
    "You've reached today's upload limit. Please try again tomorrow! 😞",
  ],
}

export const REMAINING_SCAN_TIMES_MESSAGE_AFFIX = 'Make sure to come back tomorrow for more!'

export const resetStoreAction = createAction("RESET_STORE")

export const GOAL_NOT_FOUND = "Goal not found"

export const CALORIES_FILL_COLOR = "#0FBDD4"
export const FILL_COLOR_OVER = "#FF4921"
export const TRACK_COLOR = "rgba(0, 38, 58, 0.14)"
export const TRACK_COLOR_OVER = "#FFC800"

export const PACKAGE_TYPE = "PACKAGE"

export const GOAL_WORDING = {
  WEIGHT_LOSS:
    "Losing weight means balancing calories, protein, fat, and carbs to create a calorie deficit while nourishing your body. Cutting calories helps burn fat, while protein preserves muscle and keeps your metabolism active. Healthy fats keep you fuller longer and support nutrient absorption. Carbs provide energy for workouts and daily activities, helping you stay active. Together, these nutrients support weight loss, muscle maintenance, and overall well-being",
  MAINTAIN:
    "Keeping your weight steady is all about balancing key nutrients: calories, protein, fat, and carbs. Calories power you through your day, protein keeps your muscles strong, fats support hormone health, and carbs keep your brain and body fueled. By consuming the right amounts of these nutrients, you can maintain a stable weight, stay energized, and support your overall health.",
  BUILDING:
    "Gaining weight in a healthy way requires an increase in calories, protein, fat, and carbs to support growth and energy. Calories fuel mass building, protein aids muscle growth, fats provide energy and support hormone balance, while carbs boost stamina and muscle development. These nutrients work together for balanced, sustainable weight gain.",
  NOT_SURE:
    "Keeping your weight steady is all about balancing key nutrients: calories, protein, fat, and carbs. Calories power you through your day, protein keeps your muscles strong, fats support hormone health, and carbs keep your brain and body fueled. By consuming the right amounts of these nutrients, you can maintain a stable weight, stay energized, and support your overall health.",
}

export const DATE_PARAM_FORMAT = "YYYY-MM-DD"
export const US_DATE_FORMAT = "MM/DD/YYYY"
export const US_DATE_FORMAT_WITHOUT_YEAR = "MMM DD"
export const US_DATE_FORMAT_WITH_YEAR = "MMM DD, YYYY"

export const MAINTAIN_WEIGHT_TYPES = ["MAINTAIN", "NOT_SURE"]
