import { Collapse } from "antd-mobile"
import { IIngredient } from "features/meal/types"
import styled from "styled-components"
import { getDisplayCalories, getDisplayNumber } from "utils"

const IngredientsWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  background: #fff;
  padding: var(--spacing_xxs, 4px) var(--spacing_m, 12px);
  --adm-color-background: #fff;
`

const IngredientsCollapse = styled(Collapse)`
  .adm-list {
    background: #fff;
    color: var(--color-dark);
    --border-inner: 1px solid rgba(28, 28, 30, 0.24);
    --padding-left: 0px;
    --padding-right: 0px;
  }

  a.adm-list-item:active:not(.adm-list-item-disabled) {
    &:active {
      background: #fff;
    }
  }

  .adm-list-body {
    border: none;
    background: #fff;
    color: var(--color-dark);
    .adm-list-item.has-border {
      border-bottom: var(--border-inner);
    }

    .adm-list-item-content {
      border-top: none;
    }

    .adm-list-item-content:not(:last-child) {
    }

    .adm-list-item-content-main {
      padding: 6px 4px;
    }

    .adm-collapse-panel-content {
      .adm-list-item-content-main {
        padding: 0;
      }
    }

    .adm-list-item-content-arrow {
      margin-left: 2px;
      font-size: 16px;
      .adm-collapse-arrow {
        height: 20px;

        svg {
          path {
            stroke: #69757d;
          }
        }
      }
    }
  }
`

const IngredientTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IngredientTitle = styled.div`
  font-size: 16px;
  letter-spacing: -0.43px;
  display: flex;
  color: var(--color-dark);

  span {
    font-weight: 400;
    margin-left: 0.5px;
    color: var(--color-dark);
  }
`

const IngredientContent = styled.div`
  background: var(--Schemes-Surface-Container, #ebedf0);
`
const IngredientContentItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  font-size: 14px;
  color: #1c1c1e;
  letter-spacing: -0.23px;
  gap: 10px;
`

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 0px;

  &:last-child {
    border-top: 1px solid #000;
  }
`

const TotalText = styled(IngredientTitle)`
  font-weight: 700;
  padding: 6px 0px;
  line-height: 140%;

  span {
    font-weight: 400;
    margin-left: 0.5px;
    line-height: 140%;
  }
`
const Divider = styled.div`
  height: 4px;
  background: #000;
  width: 100%;
`
const IngredientContentItem = ({
  label,
  value,
}: {
  label: string
  value: number | string
}) => {
  return (
    <IngredientContentItemWrapper>
      <div>{label}</div>
      <div>{value}</div>
    </IngredientContentItemWrapper>
  )
}

const calculateTotal = (
  ingredients: IIngredient[],
): { gam: number; cal: number } => {
  return ingredients.reduce(
    (acc, ingredient) => {
      return {
        gam: acc.gam + ingredient.amount,
        cal: acc.cal + ingredient.calorie,
      }
    },
    { gam: 0, cal: 0 },
  )
}

const Ingredients = ({
  ingredients,
  defaultActive = true,
}: {
  ingredients: IIngredient[]
  defaultActive?: boolean
}) => {
  const { gam, cal } = calculateTotal(ingredients)
  return (
    <IngredientsWrapper>
      <IngredientsCollapse
        defaultActiveKey={defaultActive ? ["0"] : []}
        arrowIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            style={{ strokeWidth: 0 }}
          >
            <g id="16 x 16 App Icons">
              <path
                id="Vector 35 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.16004 6.21043C4.38866 5.94772 4.77786 5.92836 5.02934 6.16719L8 8.98835L10.9707 6.16719C11.2221 5.92836 11.6113 5.94772 11.84 6.21043C12.0686 6.47314 12.05 6.87971 11.7986 7.11854L8.41395 10.3328C8.17923 10.5557 7.82077 10.5557 7.58605 10.3328L4.20144 7.11854C3.94995 6.87971 3.93142 6.47314 4.16004 6.21043Z"
                fill="#69757D"
              />
            </g>
          </svg>
        }
      >
        {ingredients.map((ingredient, index) => {
          return (
            <IngredientsCollapse.Panel
              className="has-border"
              key={index + ""}
              title={
                <IngredientTitleWrapper>
                  <IngredientTitle>{ingredient.name}</IngredientTitle>
                  <IngredientTitle>
                    {ingredient.amount}
                    {" g"}
                  </IngredientTitle>
                </IngredientTitleWrapper>
              }
            >
              <IngredientContent>
                <IngredientContentItem
                  label="Calories (cal)"
                  value={getDisplayCalories(ingredient.calorie)}
                />

                <IngredientContentItem
                  label="Protein (g)"
                  value={getDisplayNumber(ingredient.protein)}
                />

                <IngredientContentItem
                  label="Fat (g)"
                  value={getDisplayNumber(ingredient.fat)}
                />

                <IngredientContentItem
                  label="Carbs (g)"
                  value={getDisplayNumber(ingredient.carbohydrate)}
                />
              </IngredientContent>
            </IngredientsCollapse.Panel>
          )
        })}
      </IngredientsCollapse>
      <Divider />
      <TotalRow style={{ paddingRight: 22 }}>
        <TotalText> Amount: </TotalText>
        <TotalText>
          {getDisplayNumber(gam)}
          <span>{" g"} </span>
        </TotalText>
      </TotalRow>
      <TotalRow style={{ paddingRight: 2 }}>
        <TotalText> Calories: </TotalText>
        <TotalText>
          {getDisplayCalories(cal)}
          {" cal"}
        </TotalText>
      </TotalRow>
    </IngredientsWrapper>
  )
}

export default Ingredients
