import React from "react"

const CollapsedChatIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      onClick={onClick}
      style={{ position: "absolute", left: 40, bottom: 26 }}
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="23"
      viewBox="0 0 47 23"
      fill="none"
    >
      <rect x="3" y="1" width="43" height="21" rx="10.5" fill="white" />
      <rect
        x="3"
        y="1"
        width="43"
        height="21"
        rx="10.5"
        stroke="white"
        strokeWidth="0.631579"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.59618 15.172C3.51127 14.7326 3.4668 14.2788 3.4668 13.8147V10.6334H10.5779V20.9258C8.85588 20.9258 7.27681 20.3137 6.04641 19.2952C4.81895 20.1131 2.85039 20.8908 0.471955 20.4583C1.12693 20.1776 3.65324 18.4934 3.55968 15.125C3.57134 15.141 3.58351 15.1567 3.59618 15.172Z"
        fill="white"
      />
      <path
        d="M31.2253 13.888C30.1693 13.888 29.2812 13 29.2812 11.944C29.2812 10.888 30.1693 10 31.2253 10C32.2813 10 33.1693 10.888 33.1693 11.944C33.1693 13 32.2813 13.888 31.2253 13.888Z"
        fill="#0FBDD4"
      />
      <path
        d="M25.0846 13.888C24.0286 13.888 23.1406 13 23.1406 11.944C23.1406 10.888 24.0286 10 25.0846 10C26.1406 10 27.0286 10.888 27.0286 11.944C27.0286 13 26.1406 13.888 25.0846 13.888Z"
        fill="#0FBDD4"
      />
      <path
        d="M18.944 13.888C17.888 13.888 17 13 17 11.944C17 10.888 17.888 10 18.944 10C20 10 20.888 10.888 20.888 11.944C20.888 13 20 13.888 18.944 13.888Z"
        fill="#0FBDD4"
      />
    </svg>
  )
}

export default CollapsedChatIcon
