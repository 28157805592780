import { useEffect, useState } from "react"
import { IIngredient, IManualAddStep, IPortion } from "../types"
import {
  getStandardIngredient,
  isPortionValid,
  replaceIngredientByName,
  updateIngredientsWhenPortionChange,
  updateNutritionWhenAmountChange,
} from "../mealHelper"
import { truncateByDecimalPlace } from "utils"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { getMealUnits } from "../mealSlice"
import { useFailed } from "features/notification/hooks"

export interface IManualAddMealPayload {
  name: string
  ingredients: IIngredient[]
  portion: { first: number; second: number }
  servings: string
}

export const useManualAddMeal = () => {
  const [step, setStep] = useState<IManualAddStep>("main")
  const [standardIngredients, setStandardIngredients] = useState<IIngredient[]>(
    [],
  )
  const [feedback, setFeedBack] = useState<IManualAddMealPayload>({
    name: "",
    ingredients: [],
    servings: "1/1",
    portion: { first: 1, second: 1 },
  })

  const [editingIngredient, setEditingIngredient] = useState<IIngredient>()
  const [addingIngredient, setAddingIngredient] = useState<IIngredient>()
  const [standardIngredient, setStandardIngredient] = useState<IIngredient>()

  const onSelectIngredientToEdit = (ingredient: IIngredient) => {
    if (ingredient) {
      const standardIngredient = standardIngredients.find(
        ({ name }) => name === ingredient.name,
      )
      setStandardIngredient(standardIngredient)
      setEditingIngredient(ingredient)
      setStep("edit-ingredient")
    }
  }

  const onFinishEditing = () => {
    setStep("main")
    setStandardIngredient(undefined)
    setEditingIngredient(undefined)

    if (feedback && editingIngredient && standardIngredient) {
      const localIngredients = replaceIngredientByName(
        editingIngredient,
        feedback.ingredients,
      )

      setFeedBack({
        ...feedback,
        ingredients: localIngredients,
      })

      const standardIngredient = getStandardIngredient(
        editingIngredient,
        feedback.portion,
      )

      const nextStandardIngredients = replaceIngredientByName(
        standardIngredient,
        standardIngredients,
      )

      setStandardIngredients(nextStandardIngredients)
    }
  }

  const onEditingIngredientAmountChange = (amount?: number) => {
    if (!feedback || !editingIngredient || !standardIngredient) {
      return
    }

    let numberValue = Number(amount)

    if (isNaN(numberValue)) {
      numberValue = 0
    }

    const updatedIngredient = updateNutritionWhenAmountChange({
      nextAmount: numberValue,
      standardIngredient,
    })

    setEditingIngredient(updatedIngredient)
  }

  const onStartAddingIngredient = (addedIngredient: IIngredient) => {
    setAddingIngredient(addedIngredient)
    setStandardIngredient(addedIngredient)
    setStep("add-ingredient")
  }

  const onAddingIngredientAmountChange = (amount: number) => {
    if (!standardIngredient || !addingIngredient) {
      return
    }

    const getNextValue = (originVal: number) => {
      return truncateByDecimalPlace(
        (originVal / standardIngredient.amount) * amount,
        1,
      )
    }
    setAddingIngredient({
      ...addingIngredient,
      amount,
      calorie: getNextValue(standardIngredient.calorie),
      fat: getNextValue(standardIngredient.fat),
      carbohydrate: getNextValue(standardIngredient.carbohydrate),
      protein: getNextValue(standardIngredient.protein),
    })
  }

  const onFinishAdding = () => {
    if (!addingIngredient || !feedback) {
      return
    }

    setFeedBack({
      ...feedback,
      ingredients: [addingIngredient, ...feedback.ingredients],
    })

    const addingStandardIngredient = getStandardIngredient(
      addingIngredient,
      feedback.portion,
    )

    setStandardIngredients([addingStandardIngredient, ...standardIngredients])

    setStep("main")
    setAddingIngredient(undefined)
    setStandardIngredient(undefined)
  }

  const onDeleteIngredient = () => {
    if (!feedback || !editingIngredient) return

    const nextLocalIngredients = feedback.ingredients.filter(
      ({ name }) => name !== editingIngredient.name,
    )
    setFeedBack({ ...feedback, ingredients: nextLocalIngredients })

    setStandardIngredients(() =>
      standardIngredients.filter(({ name }) => name !== editingIngredient.name),
    )

    setStep("main")
  }

  const onNameChange = (value: string) => {
    if (!feedback) return

    setFeedBack({ ...feedback, name: value })
  }

  const onPortionChange = (portion: IPortion) => {
    if (!feedback) {
      return
    }

    if (!isPortionValid(portion)) {
      setFeedBack({
        ...feedback,
        portion,
      })
      return
    }

    if (feedback) {
      setFeedBack({
        ...feedback,
        portion,
        servings: `${portion.first}/${portion.second}`,
        ingredients: updateIngredientsWhenPortionChange({
          ingredients: feedback.ingredients,
          standardIngredients,
          portion,
        }),
      })
    }
  }

  return {
    step,
    setStep,

    feedback,

    editingIngredient,
    onSelectIngredientToEdit,
    onEditingIngredientAmountChange,
    onFinishEditing,

    addingIngredient,
    onStartAddingIngredient,
    onAddingIngredientAmountChange,
    onFinishAdding,

    onDeleteIngredient,

    onNameChange,
    onPortionChange,
  }
}

export const useMealUnits = () => {
  const mealUnits = useAppSelector((state) => state.meal.mealUnits)
  const getMealUnitsLoading = useAppSelector(
    (state) => state.meal.getMealUnitsLoading,
  )
  const getMealUnitsFailed = useAppSelector(
    (state) => state.meal.getMealUnitsFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!mealUnits.length) {
      dispatch(getMealUnits())
    }
  }, [])

  useFailed(getMealUnitsFailed)

  return {
    mealUnits,
    getMealUnitsLoading,
    getMealUnitsFailed,
  }
}
