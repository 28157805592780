import {
  FoodDetailsWrapper,
  FoodImage,
  FoodImageBox,
  FoodImageOverlay,
  FoodImagePlaceholder,
  FoodName,
  IngredientBox,
  StyledMealDetails,
} from "./styled"
import NutritionInformation from "./NutritionInformation"
import IngredientInformation from "./IngredientsInfomation"
import { IFeedback, IPortion } from "features/meal/types"
import { useNutritionInformation } from "features/meal/hooks/mealDetailsHooks"
import largeMealPlaceholder from "../../../large-meal-placeholder.png"
import { ImageViewer } from "antd-mobile"
import { Fragment, useState } from "react"
import ClosePreview from "components/Icons/ClosePreview"
import { decimalToFraction, truncateByDecimalPlace } from "utils"
import InputNumberPickerView from "components/InputNumberPicker"

const MealInformation = ({
  meal,
  onPortionChange,
  style = {},
}: {
  meal: IFeedback
  onPortionChange: (portion: IPortion) => void
  style?: React.CSSProperties
}) => {
  const ingredients = meal.ingredients

  const {
    fatAmount,
    carbAmount,
    proteinAmount,
    totalCalories,
    totalAmount,
    proteinPercent,
    fatPercent,
    carbPercent,
  } = useNutritionInformation(ingredients)

  const previewImageUrl = meal.previewImageUrl ?? meal.imageUrl

  const [imagePreviewerVisible, setImagePreviewerVisible] = useState(false)

  const decimalServing = truncateByDecimalPlace(
    meal.portion.first / meal.portion.second,
  )

  return (
    <StyledMealDetails
      style={{
        paddingTop: 0,
        justifyContent: "start",
        paddingBottom: 10,
        ...style,
      }}
    >
      <ImageViewer
        image={previewImageUrl}
        visible={imagePreviewerVisible}
        onClose={() => {
          setImagePreviewerVisible(false)
        }}
      />

      <FoodDetailsWrapper>
        <FoodImageBox
          onClick={() => {
            if (previewImageUrl) {
              setImagePreviewerVisible(true)
            }
          }}
        >
          {previewImageUrl ? (
            <Fragment>
              <FoodImage src={previewImageUrl} />
              {imagePreviewerVisible && (
                <ClosePreview onClick={() => setImagePreviewerVisible(false)} />
              )}
            </Fragment>
          ) : (
            <FoodImagePlaceholder src={largeMealPlaceholder} />
          )}

          <FoodImageOverlay />

          <FoodName>{meal.name}</FoodName>
        </FoodImageBox>
      </FoodDetailsWrapper>

      <div
        style={{
          width: "calc(100% - 16px)",
          margin: "0 auto",
          marginTop: -45,
          zIndex: 999,
          marginBottom: 12,
        }}
      >
        <InputNumberPickerView
          label="Your portion"
          value={decimalServing}
          detailTrailing={meal.unit}
          onChange={(value) => {
            const { numerator, denominator } = decimalToFraction(value)

            onPortionChange({
              first: numerator,
              second: denominator,
            })
          }}
        />
      </div>

      <div
        style={{
          width: "calc(100% - 16px)",
          margin: "0 auto",
        }}
      >
        <NutritionInformation
          proteinPercent={proteinPercent}
          fatPercent={fatPercent}
          carbPercent={carbPercent}
          totalCalories={totalCalories}
          fatAmount={fatAmount}
          carbAmount={carbAmount}
          proteinAmount={proteinAmount}
        />
      </div>

      <IngredientBox>
        <IngredientInformation
          totalAmount={totalAmount}
          totalCalorie={totalCalories}
          ingredients={meal.ingredients}
        />
      </IngredientBox>
    </StyledMealDetails>
  )
}

export default MealInformation
