import { FlexBox } from "components/Common/FlexBox"
import React from "react"
import {
  IngredientAmount,
  IngredientName,
  IngredientTitle,
  StyledIngredient,
} from "./styled"
import SmallChevronRight from "components/Icons/SmallChevronRight"
import { IIngredient } from "features/meal/types"
import { getDisplayNumber } from "utils"

const Ingredient = ({
  name,
  amount,
  onSelect,
  disabled,
}: {
  name: string
  amount: number
  onSelect: () => void
  disabled: boolean
}) => {
  return (
    <StyledIngredient
      $disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onSelect()
        }
      }}
    >
      <IngredientName>{name}</IngredientName>
      <IngredientAmount>{getDisplayNumber(amount)} g</IngredientAmount>
      <SmallChevronRight fill={"#69757D"} />
    </StyledIngredient>
  )
}

const Ingredients = ({
  ingredients,
  onSelectIngredientToEdit,
  disabled = false,
}: {
  ingredients: IIngredient[]
  onSelectIngredientToEdit: (ingredient: IIngredient) => void
  disabled?: boolean
}) => {
  return (
    <FlexBox $direction="column" $gap={12} style={{ marginTop: 24 }}>
      <IngredientTitle>Ingredients</IngredientTitle>
      <FlexBox $direction="column" $gap={6}>
        {ingredients.map((ingredient, index) => (
          <Ingredient
            disabled={disabled}
            onSelect={() => onSelectIngredientToEdit(ingredient)}
            key={ingredient.name}
            name={ingredient.name}
            amount={ingredient.amount}
          />
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default Ingredients
