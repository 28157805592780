import React from "react"

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 7V5.5H14.25V7H9.75ZM8.25 7V5C8.25 4.73478 8.35536 4.48043 8.54289 4.29289C8.73043 4.10536 8.98478 4 9.25 4H14.75C15.0152 4 15.2696 4.10536 15.4571 4.29289C15.6446 4.48043 15.75 4.73478 15.75 5V7H18.25C18.4489 7 18.6397 7.07902 18.7803 7.21967C18.921 7.36032 19 7.55109 19 7.75C19 7.94891 18.921 8.13968 18.7803 8.28033C18.6397 8.42098 18.4489 8.5 18.25 8.5H17.885L17.142 18.153C17.1034 18.6554 16.8767 19.1248 16.507 19.4673C16.1373 19.8097 15.6519 20 15.148 20H8.852C8.34808 20 7.86274 19.8097 7.49304 19.4673C7.12335 19.1248 6.89655 18.6554 6.858 18.153L6.115 8.5H5.75C5.55109 8.5 5.36032 8.42098 5.21967 8.28033C5.07902 8.13968 5 7.94891 5 7.75C5 7.55109 5.07902 7.36032 5.21967 7.21967C5.36032 7.07902 5.55109 7 5.75 7H8.25ZM7.62 8.5H16.38L15.646 18.038C15.6364 18.1636 15.5798 18.2809 15.4875 18.3666C15.3952 18.4522 15.2739 18.4999 15.148 18.5H8.852C8.72607 18.4999 8.60482 18.4522 8.5125 18.3666C8.42017 18.2809 8.36357 18.1636 8.354 18.038L7.62 8.5Z"
        fill="#1C1F21"
      />
    </svg>
  )
}

export default DeleteIcon
