import { IIngredient } from "features/meal/types"
import {
  Divider,
  IngredientContent,
  IngredientsCollapse,
  IngredientTitle,
  IngredientTitleWrapper,
  SlightDivider,
  TotalRow,
  TotalText,
  Wrapper,
  IngredientContentItemWrapper,
} from "./styled"
import { getDisplayCalories, getDisplayNumber } from "utils"

const IngredientContentItem = ({
  label,
  value,
}: {
  label: string
  value: number | string
}) => {
  return (
    <IngredientContentItemWrapper>
      <div>{label}</div>
      <div>{value}</div>
    </IngredientContentItemWrapper>
  )
}

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ strokeWidth: 0 }}
    >
      <g id="16 x 16 App Icons">
        <path
          id="Vector 35 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16004 6.21043C4.38866 5.94772 4.77786 5.92836 5.02934 6.16719L8 8.98835L10.9707 6.16719C11.2221 5.92836 11.6113 5.94772 11.84 6.21043C12.0686 6.47314 12.05 6.87971 11.7986 7.11854L8.41395 10.3328C8.17923 10.5557 7.82077 10.5557 7.58605 10.3328L4.20144 7.11854C3.94995 6.87971 3.93142 6.47314 4.16004 6.21043Z"
          fill="#69757D"
        />
      </g>
    </svg>
  )
}

const IngredientInformation = ({
  ingredients,
  totalAmount,
  totalCalorie,
}: {
  ingredients: IIngredient[]
  totalAmount: number
  totalCalorie: number
}) => {
  return (
    <Wrapper>
      <TotalRow style={{ paddingRight: 4 }}>
        <TotalText>Amount</TotalText>
        <TotalText>
          {getDisplayCalories(totalAmount)} {" g"}
        </TotalText>
      </TotalRow>
      <SlightDivider />

      <TotalRow style={{ paddingRight: 4 }}>
        <TotalText>Calories</TotalText>
        <TotalText>
          {getDisplayCalories(totalCalorie)} {" cal"}
        </TotalText>
      </TotalRow>

      <Divider />

      <IngredientsCollapse defaultActiveKey={[]} arrowIcon={<ArrowIcon />}>
        {ingredients.map((ingredient, index) => {
          return (
            <IngredientsCollapse.Panel
              className={index !== ingredients.length - 1 ? "has-border" : ""}
              key={index + ""}
              title={
                <IngredientTitleWrapper>
                  <IngredientTitle>{ingredient.name}</IngredientTitle>
                  <IngredientTitle>
                    {getDisplayNumber(ingredient.amount)} {" g"}
                  </IngredientTitle>
                </IngredientTitleWrapper>
              }
            >
              <IngredientContent>
                <IngredientContentItem
                  label="Calories (cal)"
                  value={getDisplayCalories(ingredient.calorie)}
                />

                <IngredientContentItem
                  label="Protein (g)"
                  value={getDisplayNumber(ingredient.protein)}
                />

                <IngredientContentItem
                  label="Fat (g)"
                  value={getDisplayNumber(ingredient.fat)}
                />

                <IngredientContentItem
                  label="Carbs (g)"
                  value={getDisplayNumber(ingredient.carbohydrate)}
                />
              </IngredientContent>
            </IngredientsCollapse.Panel>
          )
        })}
      </IngredientsCollapse>
    </Wrapper>
  )
}

export default IngredientInformation
