import ImageUploader from "components/ImageUploader/ImageUploader"
import { useRef } from "react"
import { styled } from "styled-components"
import {
  useCreateMeal,
  useFeedback,
  useLogMealStep,
  useProcessImage,
  useUploadImage,
} from "features/meal/hooks/hooks"
import { useAppSelector } from "app/hooks"
import Button from "components/Buttons/Button"
import MealIcon from "components/Icons/MealIcon"

const ChatInputWrapper = styled.div`
  width: 100%;

  .adm-image-uploader {
    --cell-size: 60px;
  }
`

const ChatInputImageBox = styled.div<{ $hasImage: boolean }>`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.$hasImage ? "10px" : "0px")};
  padding-top: ${(props) => (props.$hasImage ? "5px" : "0px")};
`

export const OptionBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  & > * {
    width: 50%;
  }
`

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const safariVersion = navigator.userAgent.match(/Version\/(\d+)/)
let isSafari17 = isSafari && safariVersion && parseInt(safariVersion[1]) >= 17

const getBase64 = (file: File | Blob, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(file)
}

const ChatInput = ({ disableScan }: { disableScan: boolean }) => {
  const step = useAppSelector((state) => state.meal.step)
  const { onUploadImage } = useUploadImage()
  const { processImageLoading, handleProcessImage } = useProcessImage()
  const { handleCreateMeal } = useCreateMeal()

  const uploadRef = useRef<HTMLInputElement>(null)

  const onImageChange = (file?: File) => {
    if (file) {
      const isHeicFile = file.type === "image/heic"
      const isHeifFile = file.type === "image/heif"
      const shouldConvert = isHeicFile || isHeifFile

      if (shouldConvert && !isSafari17) {
        handleProcessImage(file)
      } else {
        getBase64(file, (base64) => {
          onUploadImage(base64, file)
        })
      }
    }
  }

  let element = null

  switch (step) {
    case "init":
      element = processImageLoading ? null : (
        <Button
          disabled={disableScan}
          $type="primary"
          style={{
            width: "100%",
            color: "color: var(--Schemes-On-Primary-Container, #044257)",
          }}
          onClick={() => {
            uploadRef.current?.click()
          }}
        >
          <MealIcon />
          Upload your meal
        </Button>
      )
      break

    case "uploaded":
      element = (
        <OptionBox>
          <Button
            $type="secondary"
            onClick={() => {
              uploadRef.current?.click()
            }}
          >
            Select another
          </Button>
          <Button
            $type="primary"
            onClick={() => handleCreateMeal({ manual: false })}
          >
            Send this
          </Button>
        </OptionBox>
      )
      break

    default:
      break
  }

  return (
    <ChatInputWrapper>
      <ChatInputImageBox $hasImage={false}>
        {element}
        <ImageUploader
          ref={uploadRef}
          onChange={(image?: File) => {
            onImageChange(image)
          }}
          loading={false}
          displayedUrl={""}
          isUploadedFailed={false}
        />
      </ChatInputImageBox>
    </ChatInputWrapper>
  )
}

export default ChatInput
