import { Navigate } from "react-router-dom"
import PATHS from "./paths"
import type { RouteObject } from "react-router-dom"

import AuthLayout from "components/Layouts/AuthLayout"
import AppLayout from "components/Layouts/AppLayout"

import SetGoal from "features/onboarding/SetGoal"
import Home from "features/home"
import Auth from "features/auth/Components/Auth"
import LogInWithEmail from "features/auth/Components/LogInWithEmail"
import PersonalInfo from "features/onboarding/PersonalInfo"
import AddRecentMeal from "features/meal/AddRecentMeal"
import ScanMeal from "features/meal/ScanMeal/index"
import Profile from "features/profile/Profile"
import BodyMetric from "features/profile/BodyMetric"
import Overview from "features/overview/Overview"
import TermOfUse from "features/auth/Components/TermOfUse"
import EditMeal from "features/meal/EditMeal"
import MealDetails from "features/meal/MealDetails"
import ManualAdd from "features/meal/ManualAdd"
import RecentMeals from "features/meal/Recent"
import Tutorial from "features/meal/Tutorial"
import YourGoal from "features/profile/YourGoal"

export const routes: RouteObject[] = [
  {
    path: PATHS.app.root,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: PATHS.app.meal.recent,
        element: <RecentMeals />,
      },

      {
        path: PATHS.app.meal.tutorial,
        element: <Tutorial />,
      },

      {
        path: PATHS.app.meal.addRecent,
        element: <AddRecentMeal />,
      },
      {
        path: PATHS.app.meal.scan,
        element: <ScanMeal />,
      },
      {
        path: PATHS.app.meal.details,
        element: <MealDetails />,
      },
      {
        path: PATHS.app.meal.edit,
        element: <EditMeal />,
      },
      {
        path: PATHS.app.meal.manualAdd,
        element: <ManualAdd />,
      },

      { path: PATHS.app.yourGoal, element: <YourGoal /> },
      { path: PATHS.app.metric, element: <BodyMetric /> },
      { path: PATHS.app.profile, element: <Profile /> },
      {
        path: PATHS.app.personalInfo,
        element: <PersonalInfo />,
      },
      {
        path: PATHS.app.setGoal,
        element: <SetGoal />,
      },
      {
        path: PATHS.app.updateGoal,
        element: <SetGoal />,
      },

      { path: PATHS.app.overview, element: <Overview /> },
    ],
  },

  {
    path: PATHS.auth.root,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      { path: PATHS.auth.loginWithEmail, element: <LogInWithEmail /> },
    ],
  },

  { path: PATHS.auth.termOfUse, element: <TermOfUse /> },

  {
    path: "*",
    element: <Navigate to={PATHS.app.root} />,
  },
]

export default routes
