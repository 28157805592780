import styled from "styled-components"

const InfoSection = styled.div<{ $bold?: boolean; $noBorder?: boolean }>`
  padding: var(--spacing_xs, 6px) 0px;
  border-radius: var(--spacing_s, 8px);

  border: ${({ $noBorder }) =>
    $noBorder
      ? "none"
      : "0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14))"};
  background: ${({ $bold }) =>
    $bold
      ? "var(--Schemes-Surface-Container, #EBEDF0)"
      : "var(--Schemes-Surface-Container-Lowest, #fff)"};
`

export default InfoSection
