import { useDuplicateMeal, useSetMealTypeParams } from "../hooks/hooks"
import styled from "styled-components"
import { useLocalFeedback, useMealDetails } from "../hooks/mealDetailsHooks"
import { isPortionValid } from "../mealHelper"
import { FlexBox } from "components/Common/FlexBox"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import { FoodDetailsCta, FoodDetailsMain, PageTitle } from "./styled"
import MealInformation from "components/MealInformation"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import Button from "components/Buttons/Button"
import { useSetPageTitle } from "libs/ga"

const Wrapper = styled.div`
  height: 100%;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
  --footer-height: 84px;
`

const AddRecentMeal = () => {
  useSetPageTitle("Add Recent Meal")
  const mealType = useSetMealTypeParams()
  const { mealDetails } = useMealDetails()
  const { handleDuplicateMeal, duplicatedMealLoading } = useDuplicateMeal()
  const { feedback, onPortionChange } = useLocalFeedback(mealDetails)

  const navigate = useNavigate()

  const isDisabled = feedback?.portion
    ? !isPortionValid(feedback?.portion)
    : true

  return (
    feedback && (
      <Wrapper>
        <FlexBox
          style={{ height: 44, padding: "9px 12px" }}
          $justify="space-between"
          $alignItems="center"
        >
          <BackButton
            onClick={() => {
              if (!duplicatedMealLoading) {
                navigate(`${PATHS.app.meal.recent}?mealType=${mealType}`)
              }
            }}
          >
            <ChevronLeft />
          </BackButton>

          <PageTitle>Food Detail</PageTitle>
          <div></div>
        </FlexBox>

        <FoodDetailsMain>
          <MealInformation
            style={{ height: "100%" }}
            meal={feedback}
            onPortionChange={onPortionChange}
          />
        </FoodDetailsMain>

        <FoodDetailsCta>
          <Button
            disabled={isDisabled}
            loading={duplicatedMealLoading}
            onClick={() => handleDuplicateMeal(feedback)}
            style={{ width: "100%" }}
            $type="primary"
          >
            Log this Food
          </Button>
        </FoodDetailsCta>
      </Wrapper>
    )
  )
}

export default AddRecentMeal
