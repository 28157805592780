import ZozoFitAvatar from "components/ZozoFitAvatar"
import UserAvatar from "components/UserAvatar"
import {
  NEXT_LINE,
  FOOD_NAME_PREFIX,
  USER_UPLOADED_IMAGE_PREFIX,
  INGREDIENT_ITEM_PREFIX,
  OUT_OF_DAILY_SCAN_MESSAGE,
  REMAINING_SCAN_TIMES_MESSAGE_AFFIX,
} from "config"
import { MessageItem as MessageItemType } from "features/meal/types"
import Ingredients from "../Ingredients"
import { FlexBox } from "components/Common/FlexBox"
import { MessageItemWrapper, WelcomeImage, WelcomeImageBox } from "./styled"
import ScanMeal1 from "../../../assets/images/scan-meal-1.png"
import ScanMeal2 from "../../../assets/images/scan-meal-2.png"
import ScanMeal3 from "../../../assets/images/scan-meal-3.png"

const MessageItem = ({ message }: { message: MessageItemType }) => {
  const { texts, owner, ingredients, isWelcome } = message

  const isUserUploadedImageMess =
    texts.length === 1 && texts[0].includes(USER_UPLOADED_IMAGE_PREFIX)

  if (isUserUploadedImageMess) {
    return null
  }

  if (ingredients) {
    return <Ingredients ingredients={ingredients} />
  }

  return (
    <MessageItemWrapper className={`message-item--${owner}`}>
      {owner === "kagami" ? <ZozoFitAvatar /> : <UserAvatar />}

      <div className="text-content">
        {texts.map((text, index) => {
          if (text === NEXT_LINE) {
            return null
          }

          if (text.includes(FOOD_NAME_PREFIX)) {
            const foodName = text.split(FOOD_NAME_PREFIX)[1]
            return (
              <div key={index}>
                <p key={index}>Your meal is likely: </p>
                <p key={index + foodName} style={{ fontWeight: "700" }}>
                  {foodName}
                </p>
              </div>
            )
          }

          if (text.includes(INGREDIENT_ITEM_PREFIX)) {
            const ingredient = text.split(INGREDIENT_ITEM_PREFIX)[1]
            return (
              <ul style={{ margin: 0, paddingLeft: 16 }} key={ingredient}>
                <li>{ingredient} g</li>
              </ul>
            )
          }

          if(text.includes(REMAINING_SCAN_TIMES_MESSAGE_AFFIX)) {
            return (
              <p key={index} style={{ paddingRight: 25 }}>
                {text}
              </p>
            )
          }

          if (text === OUT_OF_DAILY_SCAN_MESSAGE.texts[0]) {
            return (
              <p key={index} style={{ paddingRight: 20 }}>
                {text}
              </p>
            )
          }

          return <p key={index}>{text}</p>
        })}

        {isWelcome && (
          <FlexBox $gap={10} style={{ marginTop: 10 }}>
            {[ScanMeal1, ScanMeal2, ScanMeal3].map((imageUrl) => (
              <WelcomeImageBox key={imageUrl}>
                <WelcomeImage src={imageUrl} alt="scan-meal" />
              </WelcomeImageBox>
            ))}
          </FlexBox>
        )}
      </div>
    </MessageItemWrapper>
  )
}

export default MessageItem
