import React from "react"

const ClosePreview = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      className="close-preview-btn"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick?.()
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" rx="4" fill="white" fillOpacity="0.1" />
      <g clipPath="url(#clip0_3784_12498)">
        <path
          d="M26.6894 13.3212C26.2753 12.9071 25.6064 12.9071 25.1923 13.3212L20 18.5028L14.8077 13.3106C14.3936 12.8965 13.7247 12.8965 13.3106 13.3106C12.8965 13.7247 12.8965 14.3936 13.3106 14.8077L18.5028 20L13.3106 25.1923C12.8965 25.6064 12.8965 26.2753 13.3106 26.6894C13.7247 27.1035 14.3936 27.1035 14.8077 26.6894L20 21.4972L25.1923 26.6894C25.6064 27.1035 26.2753 27.1035 26.6894 26.6894C27.1035 26.2753 27.1035 25.6064 26.6894 25.1923L21.4972 20L26.6894 14.8077C27.0929 14.4042 27.0929 13.7247 26.6894 13.3212Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3784_12498">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ClosePreview
