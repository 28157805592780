import React from "react"

const ScanMealIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="42"
      viewBox="0 0 49 42"
      fill="none"
    >
      <g id="Group 66">
        <path
          id="Union"
          d="M26.8902 15.5889C29.6674 15.2402 32.0717 16.8603 32.7879 19.6385L32.7866 19.6389C33.0384 20.6156 33.0401 21.6006 32.9266 22.5955C32.7499 24.1476 32.3045 25.6218 31.6307 27.0279C31.0065 28.3308 30.2171 29.5181 29.0718 30.4402C28.3695 31.0055 27.5848 31.4016 26.6793 31.5103C25.9787 31.5945 25.29 31.5124 24.6342 31.2391L24.6329 31.2386C24.548 31.2035 24.4507 31.2035 24.3658 31.2386L24.3645 31.2391C23.7091 31.5124 23.02 31.5945 22.3193 31.5103C21.4139 31.4016 20.6292 31.0055 19.9269 30.4402C18.782 29.5186 17.9926 28.3312 17.368 27.0279C16.6942 25.6218 16.2487 24.1476 16.0721 22.5955C15.959 21.6006 15.9603 20.6151 16.212 19.6385C16.9073 16.9416 19.1929 15.3361 21.8665 15.5633C21.7008 15.5002 21.5371 15.428 21.3754 15.3467C21.0333 15.1754 20.7272 14.9541 20.4571 14.683C19.8629 14.0865 19.4804 13.3769 19.3097 12.5541C19.1383 11.7321 19.0976 10.8963 19.1877 10.0468C20.0339 9.95644 20.8669 9.99692 21.6865 10.1683C22.5054 10.3403 23.212 10.7246 23.8062 11.3211C24.2203 11.7368 24.5217 12.2068 24.7104 12.7309C24.8999 13.2551 25.0216 13.8064 25.0756 14.3848C25.3097 13.8244 25.5935 13.2956 25.9269 12.7982C26.2597 12.3015 26.6331 11.8453 27.0473 11.4295C27.2453 11.2307 27.4974 11.1313 27.8035 11.1313C28.1096 11.1313 28.3617 11.2307 28.5598 11.4295C28.7578 11.6284 28.8569 11.8814 28.8569 12.1887C28.8569 12.4959 28.7578 12.749 28.5598 12.9478C28.1636 13.3455 27.8125 13.7836 27.5064 14.2622C27.2393 14.6805 27.0339 15.1227 26.8902 15.5889Z"
          fill="#044257"
        />
        <g id="Group 65">
          <path
            id="Vector 47 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 2.5C0.5 1.23459 1.47406 0 2.92308 0H13C13.5523 0 14 0.447715 14 1V2C14 2.55228 13.5523 3 13 3H4.5C3.94772 3 3.5 3.44772 3.5 4V10.5C3.5 11.0523 3.05228 11.5 2.5 11.5H1.5C0.947715 11.5 0.5 11.0523 0.5 10.5V2.5Z"
            fill="#044257"
          />
          <path
            id="Vector 47 (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 39.5C0.5 40.7654 1.47406 42 2.92308 42H13C13.5523 42 14 41.5523 14 41V40C14 39.4477 13.5523 39 13 39H4.5C3.94772 39 3.5 38.5523 3.5 38V31.5C3.5 30.9477 3.05228 30.5 2.5 30.5H1.5C0.947715 30.5 0.5 30.9477 0.5 31.5V39.5Z"
            fill="#044257"
          />
          <path
            id="Vector 47 (Stroke)_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5 2.5C48.5 1.23459 47.5259 0 46.0769 0H36C35.4477 0 35 0.447715 35 1V2C35 2.55228 35.4477 3 36 3H44.5C45.0523 3 45.5 3.44772 45.5 4V10.5C45.5 11.0523 45.9477 11.5 46.5 11.5H47.5C48.0523 11.5 48.5 11.0523 48.5 10.5V2.5Z"
            fill="#044257"
          />
          <path
            id="Vector 47 (Stroke)_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5 39.5C48.5 40.7654 47.5259 42 46.0769 42H36C35.4477 42 35 41.5523 35 41V40C35 39.4477 35.4477 39 36 39H44.5C45.0523 39 45.5 38.5523 45.5 38V31.5C45.5 30.9477 45.9477 30.5 46.5 30.5H47.5C48.0523 30.5 48.5 30.9477 48.5 31.5V39.5Z"
            fill="#044257"
          />
        </g>
      </g>
    </svg>
  )
}

export default ScanMealIcon
