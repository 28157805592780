import { FlexBox } from "components/Common/FlexBox"
import ChevronLeft from "components/Icons/ChevronLeft"
import { PageTitle } from "./styled"
import { Fragment, useState } from "react"
import { BackButton } from "features/meal/ScanMeal/Feedback/styled"
import Button from "components/Buttons/Button"
import { useLocalFeedback, useMealDetails } from "../hooks/mealDetailsHooks"
import { useNavigate } from "react-router-dom"
import TrashIcon from "components/Icons/TrashIcon"
import PopConfirm from "components/PopConfirm"
import { useDeleteMeal } from "features/home/hooks"
import { useBackToHome, useFeedback } from "../hooks/hooks"
import { ConfirmEntry } from "components/PopConfirm/styled"
import MealInformation from "components/MealInformation"
import { useSetPageTitle } from "libs/ga"

const MealDetails = () => {
  useSetPageTitle("Meal Details")
  const { mealDetails } = useMealDetails()
  const { feedback, onPortionChange } = useLocalFeedback(mealDetails)

  const navigate = useNavigate()
  const backToHome = useBackToHome()

  const { onSubmitFeedback, sendFeedbackLoading } = useFeedback(backToHome)
  const { handleDeleteMeal, deleteMealLoading } = useDeleteMeal(backToHome)

  const [isPopConfirmDeleteOpen, setIsPopConfirmDeleteOpen] = useState(false)
  const [isPopConfirmDiscardOpen, setIsPopConfirmDiscardOpen] = useState(false)

  const isPortionValid = (portion: { first: number; second: number }) => {
    return portion.first > 0 && portion.second > 0
  }

  const isDisabled = !feedback || !isPortionValid(feedback.portion)
  const isPortionChanged = feedback?.servings !== mealDetails?.servings

  if (!feedback) {
    return null
  }

  return (
    <Fragment>
      <div style={{ height: 6 }}></div>
      <FlexBox
        style={{
          height: 44,
          padding: "9px 12px",
          background: "var(--Schemes-Surface-Container-Low, #F5F7F7)",
        }}
        $justify="space-between"
        $alignItems="center"
      >
        <BackButton
          onClick={() => {
            if (isPortionChanged) {
              setIsPopConfirmDiscardOpen(true)
            } else {
              backToHome()
            }
          }}
        >
          <ChevronLeft />
        </BackButton>

        <PageTitle>Food Detail</PageTitle>
        <div></div>
      </FlexBox>

      <MealInformation meal={feedback} onPortionChange={onPortionChange} />

      <FlexBox
        $gap={10}
        style={{
          padding:
            "var(--spacing_s, 8px) var(--spacing_l, 16px) var(--spacing_m, 12px) var(--spacing_l, 16px)",
        }}
      >
        <Button
          disabled={sendFeedbackLoading}
          onClick={() => setIsPopConfirmDeleteOpen(true)}
          $type="default"
          $isDeleteBtn
          style={{
            padding: "var(--spacing_m, 12px) var(--spacing_l, 16px)",
          }}
        >
          <TrashIcon color="#FF4921" />
        </Button>

        <Button
          loading={sendFeedbackLoading}
          disabled={isDisabled}
          style={{ width: "100%" }}
          $type="primary"
          onClick={() => {
            if (isPortionChanged) {
              onSubmitFeedback(feedback)
            } else {
              navigate(`/meal/${feedback.id}/edit`)
            }
          }}
        >
          {isPortionChanged ? "Save Update" : "Edit"}
        </Button>
      </FlexBox>

      <PopConfirm
        isOpen={isPopConfirmDeleteOpen}
        title="Delete meal"
        onCancel={() => setIsPopConfirmDeleteOpen(false)}
        onConfirm={() => {
          handleDeleteMeal(feedback.id)
        }}
        type="danger"
        loading={deleteMealLoading}
      >
        <div style={{ textAlign: "center" }}>
          You are about to delete <ConfirmEntry>{feedback.name}</ConfirmEntry>.
          Are you sure?
        </div>
      </PopConfirm>

      <PopConfirm
        isOpen={isPopConfirmDiscardOpen}
        title="Unsaved changes?"
        cancelText="Discard"
        confirmText="Save changes"
        onCancel={() => backToHome()}
        onConfirm={() => {
          onSubmitFeedback(feedback)
        }}
        loading={sendFeedbackLoading}
      >
        <p style={{ textAlign: "center" }}>Do you want to save the changes?</p>
      </PopConfirm>
    </Fragment>
  )
}

export default MealDetails
