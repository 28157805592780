import styled from "styled-components"
export const FoodDetailsWrapper = styled.div``

export const PageTitle = styled.div`
  font-family: sf-pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 590;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  text-transform: capitalize;
`
