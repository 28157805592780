import { IHomeAPI } from "./types"
import requester from "api/requester"

const homeAPI: IHomeAPI = {
  getMeals(payload, signal) {
    return requester.get("/meals", { ...payload }, { signal })
  },
  getRecentMeals() {
    return requester.get("/meals", { page: 1, per_page: 5 })
  },

  getDailyProgress(date, signal) {
    return requester.get("/progresses/daily", { date }, { signal })
  },

  getMonthlyProgress(from, to, signal) {
    return requester.get(
      "/progresses/monthly",
      {
        from_date: from,
        to_date: to,
      },
      { signal },
    )
  },

  getAffiliateProducts() {
    return requester.get("/products")
  },

  deleteMeal: (mealId) => requester.delete(`/meals/${mealId}`),
}

export default homeAPI
