import { Collapse } from "antd-mobile"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing_xxs, 4px) var(--spacing_m, 12px);
  flex-direction: column;

  border-radius: var(--spacing_s, 8px);
  border: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
`

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 0px;
`

export const SlightDivider = styled.div`
  height: 1px;
  width: 100%;
  background: var(--Schemes-Surface-Container, #ebedf0);
`

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: var(--Schemes-On-Surface-Variant, #69757d);
`

export const IngredientTitle = styled.div`
  font-size: 16px;
  letter-spacing: -0.43px;
  display: flex;
  color: var(--color-dark);

  span {
    font-weight: 400;
    margin-left: 0.5px;
    color: var(--color-dark);
  }
`

export const TotalText = styled(IngredientTitle)`
  font-weight: 700;
  padding: 6px 0px;
  line-height: 140%;

  span {
    font-weight: 400;
    margin-left: 0.5px;
    line-height: 140%;
  }
`

export const IngredientsCollapse = styled(Collapse)`
  .adm-list {
    background: #fff;
    color: var(--color-dark);
    --border-inner: 1px solid rgba(28, 28, 30, 0.24);
    --padding-left: 0px;
    --padding-right: 0px;
  }

  a.adm-list-item:active:not(.adm-list-item-disabled) {
    &:active {
      background: #fff;
    }
  }

  .adm-list-body {
    border: none;
    background: #fff;
    color: var(--color-dark);
    .adm-list-item.has-border {
      border-bottom: var(--border-inner);
    }

    .adm-list-item-content {
      border-top: none;
      gap: 8px;
    }

    .adm-list-item-content:not(:last-child) {
    }

    .adm-list-item-content-main {
      --right-arrow-width: 40px;
      padding: 6px 0px;
      width: calc(100% - var(--right-arrow-width));
    }

    .adm-collapse-panel-content {
      .adm-list-item-content-main {
        padding: 0;
      }
    }

    .adm-list-item-content-arrow {
      font-size: 16px;
      margin-left: 0;

      .adm-collapse-arrow {
        height: 20px;

        svg {
          path {
            stroke: #69757d;
          }
        }
      }
    }
  }
`

export const IngredientTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  & > * {
    &:first-child {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      max-width: calc(100% - 80px);
    }
  }
`

export const IngredientContent = styled.div`
  background: var(--Schemes-Surface-Container, #ebedf0);
`
export const IngredientContentItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  font-size: 14px;
  color: #1c1c1e;
  letter-spacing: -0.23px;
  gap: 10px;
`
