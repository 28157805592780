const PATHS = {
  app: {
    root: "/",
    setGoal: "/set-goal",
    personalInfo: "/personal-info",
    trend: "/trend",
    settings: "/settings",
    profile: "/profile",
    overview: "/overview",
    metric: "/metric",
    yourGoal: "/your-goal",
    updateGoal: "/update-goal",

    meal: {
      tutorial: "/tutorial",
      details: "/meal/:id/details",
      edit: "/meal/:id/edit",
      scan: "/meal/scan",
      manualAdd: "/meal/manual-add",
      recent: "/meal/recent",
      addRecent: "/meal/:id/add-recent",
    },
  },
  auth: {
    root: "/auth",
    loginWithEmail: "/auth/login-with-email",
    termOfUse: "/term-of-use",
  },
}

export default PATHS
