import { ProgressBar } from "antd-mobile"
import { FlexBox } from "components/Common/FlexBox"
import { CALORIES_FILL_COLOR, TRACK_COLOR } from "config"
import styled from "styled-components"
import {
  getDisplayCalories,
  getDisplayNumber,
  truncateByDecimalPlace,
} from "utils"

const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_l, 16px)
    var(--spacing_l, 16px);
  flex-direction: column;
  gap: var(--spacing_m, 12px);

  border-radius: var(--spacing_s, 8px);
  border: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
`

const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const StyledNutritionItem = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  width: ${(props) =>
    props.$fullWidth ? "100%" : "calc(calc(100% - 32px) / 3)"};
  flex-direction: column;
  gap: 2px;
  flex-shrink: 0;
  flex-grow: 1;
`

const NutritionName = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`

const NutritionValue = styled.div<{ $isOver: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;

  color: ${({ $isOver }) =>
    $isOver
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-On-Surface, #1C1F21)"};
`

export interface INutritionItem {
  name: string
  value: string
  percent: number
  fillColor: string
  trackColor: string
}

const Nutrition = ({
  nutrition,
  fullWidth,
}: {
  nutrition: INutritionItem
  fullWidth?: boolean
}) => {
  return (
    <StyledNutritionItem $fullWidth={fullWidth}>
      <NutritionName>{nutrition.name}</NutritionName>
      <ProgressBar
        percent={nutrition.percent}
        style={{
          "--track-width": "4px",
          "--fill-color": nutrition.fillColor,
          "--track-color": nutrition.trackColor,
        }}
      />
      <NutritionValue $isOver={false}>{nutrition.value}</NutritionValue>
    </StyledNutritionItem>
  )
}

const NutritionInformation = ({
  totalCalories,
  fatAmount,
  carbAmount,
  proteinAmount,
  proteinPercent,
  fatPercent,
  carbPercent,
}: {
  totalCalories: number
  fatAmount: number
  carbAmount: number
  proteinAmount: number
  proteinPercent: number
  fatPercent: number
  carbPercent: number
}) => {
  const nutritions: INutritionItem[] = [
    {
      name: "Protein",
      value: `${getDisplayNumber(proteinAmount)} g `,
      percent: truncateByDecimalPlace(proteinPercent),
      fillColor: "var(--Accent-2, #F354E4)",
      trackColor: "#00263A24",
    },
    {
      name: "Fat",
      value: `${getDisplayNumber(fatAmount)} g `,
      percent: truncateByDecimalPlace(fatPercent),
      fillColor: "var(--Schemes-Secondary, #FFC800)",
      trackColor: "#00263A24",
    },
    {
      name: "Carbs",
      value: `${getDisplayNumber(carbAmount)} g `,
      percent: truncateByDecimalPlace(carbPercent),
      fillColor: "var(--Accent-1, #8948FA)",
      trackColor: "#00263A24",
    },
  ]

  return (
    <Wrapper>
      <Title>NUTRITIONAL INFORMATION</Title>
      <div>
        <Nutrition
          fullWidth
          nutrition={{
            name: "Calories",
            value: getDisplayCalories(totalCalories) + " cal",
            fillColor: CALORIES_FILL_COLOR,
            trackColor: TRACK_COLOR,
            percent: 100,
          }}
        />

        <FlexBox $justify="space-between" $gap={16} style={{ marginTop: 4 }}>
          {nutritions.map((item) => (
            <Nutrition key={item.name} nutrition={item} />
          ))}
        </FlexBox>
      </div>
    </Wrapper>
  )
}

export default NutritionInformation
