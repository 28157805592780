import EditMealGeneral from "./General"
import EditIngredient from "./EditIngredient"
import { useIngredientOptions } from "../hooks/hooks"
import SearchIngredient from "./SearchIngredient"
import AddIngredient from "./AddIngredient"
import { useMealDetails, useUpdateFeedback } from "../hooks/mealDetailsHooks"
import { Wrapper } from "./styled"
import { useSetPageTitle } from "libs/ga"

const EditMeal = () => {
  useSetPageTitle("Edit Meal")

  useIngredientOptions()
  const { mealDetails } = useMealDetails()
  const {
    feedback,
    step,
    setStep,

    onNameChange,
    onPortionChange,

    addingIngredient,
    onStartAddingIngredient,
    onAddingIngredientAmountChange,
    onFinishAdding,

    editingIngredient,
    onSelectIngredientToEdit,
    onEditingIngredientAmountChange,
    onFinishEditing,

    onDeleteIngredient,
  } = useUpdateFeedback(mealDetails)

  const localMeal = { ...feedback }
  delete localMeal.portion

  const isMealChanged =
    JSON.stringify(mealDetails) !== JSON.stringify(localMeal)

  return (
    <Wrapper>
      {step === "edit" && feedback && (
        <EditMealGeneral
          onSelectIngredientToEdit={onSelectIngredientToEdit}
          feedback={feedback}
          onNameChange={onNameChange}
          onServingChange={onPortionChange}
          setStep={setStep}
          isMealChanged={isMealChanged}
        />
      )}

      {step === "edit-ingredient" && editingIngredient && (
        <EditIngredient
          editingIngredient={editingIngredient}
          onIngredientAmountChange={onEditingIngredientAmountChange}
          onFinishEditing={onFinishEditing}
          onDeleteIngredient={() => onDeleteIngredient()}
        />
      )}

      {step === "search-ingredient" && feedback && (
        <SearchIngredient
          onStartAddingIngredient={onStartAddingIngredient}
          ingredients={feedback?.ingredients}
          setStep={setStep}
        />
      )}

      {step === "add-ingredient" && addingIngredient && (
        <AddIngredient
          addingIngredient={addingIngredient}
          onFinishAdding={onFinishAdding}
          onAddingIngredientAmountChange={onAddingIngredientAmountChange}
          setStep={setStep}
        />
      )}
    </Wrapper>
  )
}

export default EditMeal
