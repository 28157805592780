import React from "react"
import { StyledInput, StyledIconBox, StyledSearchInput } from "./styled"
import SearchIcon from "components/Icons/SearchIcon"

interface IProps {
  value?: string | number | null
  onChange?: (value: string) => void
  placeholder?: string
}

const SearchInput = (props: IProps) => {
  const {} = props
  return (
    <StyledSearchInput>
      <StyledIconBox>
        <SearchIcon />
      </StyledIconBox>

      <StyledInput
        min={0}
        placeholder={props.placeholder}
        value={props.value ?? ""}
        onChange={(e) => {
          let val: any = e.target.value
          props.onChange?.(val)
        }}
        autoComplete="new-password"
        style={{ paddingRight: "16px" }}
      />
    </StyledSearchInput>
  )
}

export default SearchInput
