import { FlexBox } from "components/Common/FlexBox"
import ChevronLeft from "components/Icons/ChevronLeft"
import { PageTitle } from "components/Common/PageTitle"
import { Fragment } from "react"
import { BackButton, BottomCTABox, MainContent } from "../styled"
import TextFieldFormItem from "components/TextFieldFormItem"
import { IngredientTitle } from "../General/Ingredients/styled"
import InfoSection from "components/Common/InfoSection"

import { IIngredient } from "features/meal/types"
import Button from "components/Buttons/Button"
import InfoItem from "components/InfoItem"
import { getDisplayCalories, getDisplayNumber } from "utils"

const AddIngredient = ({
  addingIngredient,
  onUpdateAddingIngredientAmount,
  onFinishAdding,
}: {
  addingIngredient: IIngredient
  onUpdateAddingIngredientAmount: (val: number) => void
  onFinishAdding: () => void
}) => {
  return (
    <Fragment>
      <FlexBox
        style={{ height: 44, padding: "9px 10px" }}
        $alignItems="center"
        $justify="space-between"
      >
        <BackButton onClick={onFinishAdding}>
          <ChevronLeft />
        </BackButton>

        <PageTitle>{addingIngredient.name}</PageTitle>
        <div />
      </FlexBox>{" "}
      <MainContent>
        <TextFieldFormItem
          onChange={(value: string | number) =>
            onUpdateAddingIngredientAmount(Number(value))
          }
          label="Amount"
          value={addingIngredient.amount}
          type="number"
          unit="g"
        />

        <FlexBox $gap={12} $direction="column" style={{ marginTop: 24 }}>
          <IngredientTitle>Nutrient</IngredientTitle>

          <InfoSection>
            <InfoItem
              label="Calories (cal)"
              value={`${getDisplayCalories(addingIngredient.calorie)} cal`}
            />

            <InfoItem
              hasBorder
              label="Protein (g)"
              value={`${getDisplayNumber(addingIngredient.protein)} g`}
            />

            <InfoItem
              hasBorder
              label="Fat (g)"
              value={`${getDisplayNumber(addingIngredient.fat)} g`}
            />
            <InfoItem
              label="Carbs (g)"
              value={`${getDisplayNumber(addingIngredient.carbohydrate)} g`}
            />
          </InfoSection>
        </FlexBox>
      </MainContent>
      <BottomCTABox>
        <Button
          onClick={onFinishAdding}
          style={{ width: "100%" }}
          $type="primary"
        >
          Add this ingredient
        </Button>
      </BottomCTABox>
    </Fragment>
  )
}

export default AddIngredient
