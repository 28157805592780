import { useAppSelector } from "app/hooks"
import { FlexBox } from "components/Common/FlexBox"
import { Fragment, useMemo, useState } from "react"
import { BackButton } from "../styled"
import ChevronLeft from "components/Icons/ChevronLeft"
import { PageTitle } from "components/Common/PageTitle"
import SearchInput from "components/SearchInput"
import {
  IngredientAmount,
  IngredientName,
  IngredientOptions,
  IngredientOptionsWrapper,
  StyledIngredientOption,
} from "./styled"
import { IEditMealStep, IIngredient } from "features/meal/types"
import SmallChevronRight from "components/Icons/SmallChevronRight"

const IngredientOption = ({
  ingredient,
  onClick,
}: {
  ingredient: IIngredient
  onClick: () => void
}) => {
  return (
    <StyledIngredientOption onClick={() => onClick()}>
      <div>
        <IngredientName>{ingredient.name}</IngredientName>
        <IngredientAmount>{ingredient.calorie} cal</IngredientAmount>
      </div>

      <SmallChevronRight fill="#69757D" />
    </StyledIngredientOption>
  )
}

const SearchIngredient = ({
  onStartAddingIngredient,
  ingredients,
  setStep,
}: {
  onStartAddingIngredient: (ingredient: IIngredient) => void
  ingredients: IIngredient[]
  setStep: (step: IEditMealStep) => void
}) => {
  const ingredientOptions = useAppSelector(
    (state) => state.meal.ingredientOptions,
  )

  const [search, setSearch] = useState("")

  const options = useMemo(() => {
    const filteredOptions = ingredientOptions.filter(
      (option) => !ingredients.some((i) => i.name === option.name),
    )
    if (search === "" || search === null) {
      return filteredOptions
    }

    return filteredOptions.filter((option) =>
      option.name.toLowerCase().includes(search.toLowerCase()),
    )
  }, [ingredientOptions, search, ingredients])

  return (
    <Fragment>
      <FlexBox
        style={{ height: 44, padding: "9px 10px" }}
        $alignItems="center"
        $justify="space-between"
      >
        <BackButton onClick={() => setStep("edit")}>
          <ChevronLeft />
        </BackButton>

        <PageTitle>Add Ingredients</PageTitle>
        <div />
      </FlexBox>{" "}
      <FlexBox
        $direction="column"
        style={{ padding: "0px 8px", marginBottom: 12 }}
        $gap={12}
      >
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="Search for a food"
        />
      </FlexBox>
      <IngredientOptionsWrapper>
        <IngredientOptions>
          {options.map((option) => (
            <IngredientOption
              key={option.id}
              ingredient={option}
              onClick={() =>
                onStartAddingIngredient({
                  ...option,
                  amount: option.weightPerIngredient,
                })
              }
            />
          ))}
        </IngredientOptions>
      </IngredientOptionsWrapper>
    </Fragment>
  )
}

export default SearchIngredient
