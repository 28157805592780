import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  StyledInput,
  StyledLabel,
  StyledInputNumberFormItem,
  PickerPopupStyle,
  StyledPickerView,
  PickerSelectedMask,
  PickerViewContainer,
  PickerHeader,
  PickerHeaderTop,
  PickerHeaderBottom,
  DoneButton,
  DetailsTrailing,
} from "./styled"
import { Popup } from "antd-mobile"
import { useMealUnits } from "features/meal/hooks/manualAddMealHooks"

const PopupIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="4"
      viewBox="0 0 37 4"
      fill="none"
    >
      <rect
        x="0.5"
        width="36"
        height="4"
        rx="2"
        fill="#00263A"
        fillOpacity="0.14"
      />
    </svg>
  )
}

interface IProps {
  unit: string
  portion: number
  isError?: boolean
  disabled?: boolean
  onChange: (portion: number, unit: string) => void
}

const ManualPortionPicker = (props: IProps) => {
  const { onChange, portion, unit } = props
  const [visible, setVisible] = useState(false)
  const [localPortion, setLocalPortion] = useState(portion)
  const [localUnit, setLocalUnit] = useState(unit)
  const { mealUnits, getMealUnitsLoading } = useMealUnits()

  const portionColumns = useMemo(() => {
    const isValueLestThanTen = Number(localPortion) < 10
    const isValueLestThanForty = Number(localPortion) < 40

    const iterator = 40
    const step = isValueLestThanTen ? 0.25 : isValueLestThanForty ? 1 : 5
    const columns = []
    for (let i = 1; i <= iterator; i++) {
      const value = i * step
      columns.push({
        label: value.toString(),
        value,
      })
    }
    return columns
  }, [localPortion])

  const unitColumns = useMemo(() => {
    return mealUnits.map((unit) => {
      return {
        label: unit.name,
        value: unit.name,
      }
    })
  }, [mealUnits])

  useEffect(() => {
    setLocalPortion(portion)
    setLocalUnit(unit)
  }, [portion, unit, visible])

  const trailingRef = useRef<HTMLDivElement>(null)
  const [trailingWidth, setTrailWidth] = useState(0)

  useLayoutEffect(() => {
    setTrailWidth(trailingRef.current?.getBoundingClientRect().width ?? 0)
  }, [trailingRef, unit])

  const isDisabled = getMealUnitsLoading && unitColumns.length === 0
  // const isDisabled = true
  return (
    <Fragment>
      <PickerPopupStyle />
      <Popup
        onClose={() => setVisible(false)}
        closeOnMaskClick={true}
        visible={visible}
        bodyStyle={{
          borderRadius: "12px 12px 0 0",
        }}
      >
        <PickerHeader>
          <PickerHeaderTop>
            <PopupIcon />
          </PickerHeaderTop>
          <PickerHeaderBottom>
            <DoneButton
              onClick={() => {
                onChange(localPortion, localUnit)
                setVisible(false)
              }}
            >
              Done
            </DoneButton>
          </PickerHeaderBottom>
        </PickerHeader>

        <PickerViewContainer>
          <StyledPickerView
            columns={[portionColumns]}
            value={[localPortion]}
            onChange={(value) => {
              setLocalPortion(value[0] as number)
            }}
          />

          <StyledPickerView
            columns={[unitColumns]}
            value={[localUnit]}
            onChange={(value) => {
              setLocalUnit(value[0] as string)
            }}
          />

          <PickerSelectedMask />
        </PickerViewContainer>
      </Popup>

      <StyledInputNumberFormItem
        style={
          {
            "--input-padding-right": "16px",
            "--input-trailing-padding-left": "6px",
            "--detail-trailing-width": `${trailingWidth}px`,
          } as React.CSSProperties
        }
      >
        <StyledLabel>Your portion</StyledLabel>

        <StyledInput
          $isDisabled={isDisabled}
          $isError={false}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isDisabled) {
              setVisible(true)
            }
          }}
          onFocus={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          value={portion}
          onChange={(e) => {}}
          style={{
            paddingRight:
              "calc(var(--input-padding-right) + var(--input-trailing-padding-left) + var(--detail-trailing-width))",
          }}
          readOnly={true}
        />

        {unit && (
          <DetailsTrailing
            style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
            onClick={() => setVisible(true)}
          >
            {unit}
          </DetailsTrailing>
        )}

        {unit && (
          <DetailsTrailing style={{ visibility: "hidden" }} ref={trailingRef}>
            {unit}
          </DetailsTrailing>
        )}
      </StyledInputNumberFormItem>
    </Fragment>
  )
}

export default ManualPortionPicker
