import { FlexBox } from "components/Common/FlexBox"
import ChevronLeft from "components/Icons/ChevronLeft"
import DeleteIcon from "components/Icons/DeleteIcon"
import { PageTitle } from "components/Common/PageTitle"
import { Fragment } from "react"
import { BackButton, MainContent } from "../styled"
import TextFieldFormItem from "components/TextFieldFormItem"
import { IngredientTitle } from "../General/Ingredients/styled"
import InfoSection from "components/Common/InfoSection"
import { IIngredient } from "features/meal/types"
import InfoItem from "components/InfoItem"
import { getDisplayCalories, getDisplayNumber } from "utils"

const EditIngredient = ({
  editingIngredient,
  onIngredientAmountChange,
  onFinishEditing,
  onDeleteIngredient,
}: {
  editingIngredient: IIngredient
  onIngredientAmountChange: (val?: number) => void
  onFinishEditing: () => void
  onDeleteIngredient: () => void
}) => {
  const isAmountValid = !!editingIngredient.amount
  return (
    <Fragment>
      <FlexBox
        style={{ height: 44, padding: "9px 10px" }}
        $alignItems="center"
        $justify="space-between"
      >
        <BackButton
          $disabled={!isAmountValid}
          onClick={() => {
            if (isAmountValid) {
              onFinishEditing()
            }
          }}
        >
          <ChevronLeft disabled={!isAmountValid} />
        </BackButton>

        <PageTitle>{editingIngredient.name}</PageTitle>
        <BackButton onClick={onDeleteIngredient}>
          <DeleteIcon />
        </BackButton>
      </FlexBox>{" "}
      <MainContent>
        <TextFieldFormItem
          isError={!isAmountValid}
          onChange={(value) => {
            onIngredientAmountChange(value as any)
          }}
          label="Amount"
          value={editingIngredient.amount}
          type="number"
          detailTrailing="g"
        />

        <FlexBox $gap={12} $direction="column" style={{ marginTop: 24 }}>
          <IngredientTitle>Nutrient</IngredientTitle>

          <InfoSection $noBorder>
            <InfoItem
              label="Calories (cal)"
              value={`${getDisplayCalories(editingIngredient.calorie)} cal`}
            />
            <InfoItem
              hasBorder
              label="Protein (g)"
              value={`${getDisplayNumber(editingIngredient.protein)} g`}
            />

            <InfoItem
              hasBorder
              label="Fat (g)"
              value={`${getDisplayNumber(editingIngredient.fat)} g`}
            />
            <InfoItem
              label="Carbs (g)"
              value={`${getDisplayNumber(editingIngredient.carbohydrate)} g`}
            />
          </InfoSection>
        </FlexBox>
      </MainContent>
    </Fragment>
  )
}

export default EditIngredient
