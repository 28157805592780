import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { getMealDetails, setMealDetails } from "../mealSlice"
import {
  IEditMealStep,
  IFeedback,
  IIngredient,
  IMeal,
  IPortion,
} from "../types"
import { convertMealToFeedback, truncateByDecimalPlace } from "utils"
import {
  getNutritionInformation,
  getStandardIngredient,
  getStandardIngredients,
  isPortionValid,
  replaceIngredientByName,
  updateIngredientsWhenPortionChange,
  updateNutritionWhenAmountChange,
} from "../mealHelper"

export const useMealDetails = () => {
  const { id } = useParams()
  const mealDetails = useAppSelector((state) => state.meal.mealDetails)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id && id !== mealDetails?.id) {
      setMealDetails(undefined)
      dispatch(getMealDetails(id))
    }
  }, [id])

  return {
    mealDetails,
  }
}

export const useSetMealDetails = () => {
  const dispatch = useAppDispatch()

  return (mealDetails: IMeal) => {
    dispatch(setMealDetails(mealDetails))
  }
}

export const useLocalFeedback = (meal?: IMeal) => {
  const [standardIngredients, setStandardIngredients] = useState<IIngredient[]>(
    [],
  )
  const [feedback, setFeedBack] = useState(() => convertMealToFeedback(meal))

  useEffect(() => {
    const feedback = convertMealToFeedback(meal)

    if (feedback) {
      setFeedBack(feedback)
      setStandardIngredients(getStandardIngredients(feedback))
    }
  }, [meal])

  const onPortionChange = (portion: IPortion) => {
    if (!feedback) {
      return
    }

    if (!isPortionValid(portion)) {
      setFeedBack(
        (feedback) =>
          ({
            ...feedback,
            portion,
          } as IFeedback),
      )
      return
    }

    if (feedback) {
      setFeedBack((feedback) => {
        return {
          ...feedback,
          portion,
          servings: `${portion.first}/${portion.second}`,
          ingredients: updateIngredientsWhenPortionChange({
            ingredients: (feedback as IFeedback).ingredients,
            standardIngredients,
            portion,
          }),
        } as IFeedback
      })
    }
  }

  return {
    feedback,
    setFeedBack,
    standardIngredients,
    setStandardIngredients,
    onPortionChange,
  }
}

export const useUpdateFeedback = (meal?: IMeal) => {
  const {
    feedback,
    setFeedBack,
    onPortionChange,
    standardIngredients,
    setStandardIngredients,
  } = useLocalFeedback(meal)

  const [step, setStep] = useState<IEditMealStep>("edit")
  const [editingIngredient, setEditingIngredient] = useState<IIngredient>()
  const [addingIngredient, setAddingIngredient] = useState<IIngredient>()
  const [standardIngredient, setStandardIngredient] = useState<IIngredient>()

  const onSelectIngredientToEdit = (ingredient: IIngredient) => {
    if (ingredient) {
      const standardIngredient = standardIngredients.find(
        ({ name }) => name === ingredient.name,
      )
      setStandardIngredient(standardIngredient)
      setEditingIngredient(ingredient)
      setStep("edit-ingredient")
    }
  }

  const onFinishEditing = () => {
    setStep("edit")
    setStandardIngredient(undefined)
    setEditingIngredient(undefined)

    if (feedback && editingIngredient && standardIngredient) {
      const localIngredients = replaceIngredientByName(
        editingIngredient,
        feedback.ingredients,
      )

      setFeedBack({
        ...feedback,
        ingredients: localIngredients,
      })

      const standardIngredient = getStandardIngredient(
        editingIngredient,
        feedback.portion,
      )

      const nextStandardIngredients = replaceIngredientByName(
        standardIngredient,
        standardIngredients,
      )

      setStandardIngredients(nextStandardIngredients)
    }
  }

  const onEditingIngredientAmountChange = (amount?: number) => {
    if (!feedback || !editingIngredient || !standardIngredient) {
      return
    }

    let numberValue = Number(amount)

    if (isNaN(numberValue)) {
      numberValue = 0
    }

    const updatedIngredient = updateNutritionWhenAmountChange({
      nextAmount: numberValue,
      standardIngredient,
    })

    setEditingIngredient(updatedIngredient)
  }

  const onStartAddingIngredient = (addedIngredient: IIngredient) => {
    setAddingIngredient(addedIngredient)
    setStandardIngredient(addedIngredient)
    setStep("add-ingredient")
  }

  const onAddingIngredientAmountChange = (amount: number) => {
    if (!standardIngredient || !addingIngredient) {
      return
    }

    const getNextValue = (originVal: number) => {
      return truncateByDecimalPlace(
        (originVal / standardIngredient.amount) * amount,
        1,
      )
    }
    setAddingIngredient({
      ...addingIngredient,
      amount,
      calorie: getNextValue(standardIngredient.calorie),
      fat: getNextValue(standardIngredient.fat),
      carbohydrate: getNextValue(standardIngredient.carbohydrate),
      protein: getNextValue(standardIngredient.protein),
    })
  }

  const onFinishAdding = () => {
    if (!addingIngredient || !feedback) {
      return
    }

    setFeedBack({
      ...feedback,
      ingredients: [addingIngredient, ...feedback.ingredients],
    })

    const addingStandardIngredient = getStandardIngredient(
      addingIngredient,
      feedback.portion,
    )

    setStandardIngredients([addingStandardIngredient, ...standardIngredients])

    setStep("edit")
    setAddingIngredient(undefined)
    setStandardIngredient(undefined)
  }

  const onDeleteIngredient = () => {
    if (!feedback || !editingIngredient) return

    const nextLocalIngredients = feedback.ingredients.filter(
      ({ name }) => name !== editingIngredient.name,
    )
    setFeedBack({ ...feedback, ingredients: nextLocalIngredients })

    setStandardIngredients(() =>
      standardIngredients.filter(({ name }) => name !== editingIngredient.name),
    )

    setStep("edit")
  }

  const onNameChange = (value: string) => {
    if (!feedback) return

    setFeedBack({ ...feedback, name: value })
  }

  const onUnitChange = (value: string) => {
    if (!feedback) return
    setFeedBack((feedback) => ({ ...feedback, unit: value } as IFeedback))
  }

  return {
    step,
    setStep,

    feedback,

    editingIngredient,
    onSelectIngredientToEdit,
    onEditingIngredientAmountChange,
    onFinishEditing,

    addingIngredient,
    onStartAddingIngredient,
    onAddingIngredientAmountChange,
    onFinishAdding,

    onDeleteIngredient,

    onNameChange,
    onPortionChange,
    onUnitChange,
  }
}

export const useNutritionInformation = (ingredients: IIngredient[]) => {
  return useMemo(() => {
    return getNutritionInformation(ingredients)
  }, [ingredients])
}
