import { useAppDispatch, useAppSelector } from "app/hooks"
import { useFailed } from "features/notification/hooks"
import { useEffect } from "react"
import { getDailyScanProgress } from "../mealSlice"
import { ILogMealStep } from "../types"


export const useDailyScanProgress = (step: ILogMealStep) => {
  const dailyScanProgress = useAppSelector(
    (state) => state.meal.dailyScanProgress,
  )
  const getDailyScanProgressLoading = useAppSelector(
    (state) => state.meal.getDailyScanProgressLoading,
  )
  const getDailyScanProgressFailed = useAppSelector(
    (state) => state.meal.getDailyScanProgressFailed,
  )

  useFailed(getDailyScanProgressFailed)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (step === 'init') {
      dispatch(getDailyScanProgress())
    }
  }, [dispatch, step])

  const isScanDisabled =
    !dailyScanProgress ||
    dailyScanProgress.remainingScan === 0 ||
    getDailyScanProgressLoading

  return { dailyScanProgress, getDailyScanProgressLoading, isScanDisabled }
}
