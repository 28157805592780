import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const MainContent = styled.div`
  --header-height: 44px;
  --footer-height: 84px;

  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--header-height) - var(--footer-height));
  overflow-y: auto;
`

export const BottomCTABox = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_xl, 24px)
    var(--spacing_l, 16px);
  width: 100%;
  margin-top: auto;
`

export const BackButton = styled.div<{ $disabled?: boolean }>`
  width: 24px;
  height: 24px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`

export const EditMealFooter = styled(FlexBox)`
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px) var(--spacing_xl, 24px)
    var(--spacing_l, 16px);
`
