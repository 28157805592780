import styled from "styled-components"

export const StyledTermOfUse = styled.div`
  height: 100%;
  padding: 0px 16px;
`

export const TermOfUseHeader = styled.div`
  border-bottom: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--White, #fff);

  display: flex;
  width: 100%;
  padding: var(--spacing_l, 16px) 0px;

  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`

export const TermOfUseTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 120%;
`

export const TermOfUseDate = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  color: var(--Schemes-On-Surface-Variant, #69757d);
`

export const TermOfUseContent = styled.div`
  --tou-header-height: 87px;
  --tou-cta-height: 88px;

  height: calc(100% - var(--tou-header-height) - var(--tou-cta-height));
  overflow-y: auto;
`

export const TermOfUseText = styled.p`
  font-size: 16px;
  line-height: 160%;
`

export const TermOfUseSectionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin: 8px 0;
`
