import { FlexBox } from "components/Common/FlexBox"
import { NutritionRemainingTitle, StyledNutritionRemaining } from "./styled"
import styled from "styled-components"
import { ProgressBar } from "antd-mobile"
import { IDailyProgress } from "../types"
import { numberWithCommas, truncateByDecimalPlace } from "utils"

const StyledNutritionItem = styled.div`
  display: flex;
  width: 84px;
  flex-direction: column;
  gap: 2px;
`

const NutritionName = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`

const NutritionValue = styled.div<{ $isOver: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;

  color: ${({ $isOver }) =>
    $isOver
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-On-Surface, #1C1F21)"};
`

const getDisplayNutrition = (
  unit: string,
  key: string,
  dailyProgress?: any,
) => {
  if (!dailyProgress) return 0

  const nutrition = dailyProgress.pfc[key]
  const nutritionPerDay = dailyProgress.goal.pfc[key]
  const isOver = nutrition > nutritionPerDay

  let result = 0

  if (isOver) {
    result = nutrition - nutritionPerDay
  } else {
    result = nutritionPerDay - nutrition
  }

  const formatResult = numberWithCommas(truncateByDecimalPlace(result, 0))

  return `${formatResult} ${unit} ${isOver ? "over" : ""}`
}

const getDisplayPercent = (key: string, dailyProgress?: any) => {
  if (!dailyProgress) return 0

  const gainedNutrition = dailyProgress.pfc[key]
  const nutritionPerDay = dailyProgress.goal.pfc[key]
  const isOver = gainedNutrition > nutritionPerDay

  let result = 0

  if (isOver) {
    const overNutrition = gainedNutrition - nutritionPerDay
    result = (overNutrition / nutritionPerDay) * 100
  } else {
    const remainingNutrition = nutritionPerDay - gainedNutrition
    result = (remainingNutrition / nutritionPerDay) * 100
  }

  return truncateByDecimalPlace(result, 0)
}
const NutritionRemaining = ({
  dailyProgress,
}: {
  dailyProgress?: IDailyProgress
}) => {
  const isFatOver = dailyProgress
    ? dailyProgress.pfc.fat > dailyProgress.goal.pfc.fat
    : false

  const isProteinOver = dailyProgress
    ? dailyProgress.pfc.protein > dailyProgress.goal.pfc.protein
    : false

  const isCarbOver = dailyProgress
    ? dailyProgress.pfc.carb > dailyProgress.goal.pfc.carb
    : false

  const nutrition = [
    {
      name: "Protein",
      value: getDisplayNutrition("g", "protein", dailyProgress),
      isOver: isProteinOver,
      color: "var(--Accent-2, #F354E4)",
      percent: getDisplayPercent("protein", dailyProgress),
    },
    {
      name: "Fat",
      value: getDisplayNutrition("g", "fat", dailyProgress),
      isOver: isFatOver,
      color: "var(--Schemes-Secondary, #FFC800)",
      percent: getDisplayPercent("fat", dailyProgress),
    },
    {
      name: "Carbs",
      value: getDisplayNutrition("g", "carb", dailyProgress),
      isOver: isCarbOver,
      color: "var(--Accent-1, #8948FA)",
      percent: getDisplayPercent("carb", dailyProgress),
    },
  ]

  return (
    <StyledNutritionRemaining>
      <NutritionRemainingTitle>Nutrients Remaining</NutritionRemainingTitle>

      <FlexBox $justify="space-between">
        {nutrition.map((item) => {
          return (
            <StyledNutritionItem key={item.name}>
              <NutritionName>{item.name}</NutritionName>
              <ProgressBar
                percent={item.percent}
                style={{
                  "--track-width": "4px",
                  "--fill-color": item.isOver
                    ? "var(--Schemes-On-Error-Container, #FF4921)"
                    : item.color,
                  "--track-color": item.isOver
                    ? "var(--Schemes-Secondary-Container, #FFC800)"
                    : "#00263A24",
                }}
              />
              <NutritionValue $isOver={item.isOver}>
                {item.value}
              </NutritionValue>
            </StyledNutritionItem>
          )
        })}
      </FlexBox>
    </StyledNutritionRemaining>
  )
}

export default NutritionRemaining
