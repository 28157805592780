import { truncateByDecimalPlace } from "utils"
import {
  IFeedback,
  IIngredient,
  INutritionInformation,
  IPortion,
} from "./types"

const getStandardAmount = (value: number, portion: IPortion) => {
  return truncateByDecimalPlace((value * portion.second) / portion.first)
}

export const getStandardIngredient = (
  ingredient: IIngredient,
  portion: IPortion,
) => {
  const { name, amount, calorie, carbohydrate, fat, protein } = ingredient
  return {
    name: name,
    amount: getStandardAmount(amount, portion),
    calorie: getStandardAmount(calorie, portion),
    carbohydrate: getStandardAmount(carbohydrate, portion),
    fat: getStandardAmount(fat, portion),
    protein: getStandardAmount(protein, portion),
  }
}

export const getStandardIngredients = (feedback: IFeedback): IIngredient[] => {
  const { portion, ingredients } = feedback
  return ingredients.map((ingredient) =>
    getStandardIngredient(ingredient, portion),
  )
}

export const replaceIngredientByName = (
  updatedIngredient: IIngredient,
  ingredients: IIngredient[],
) => {
  return ingredients.map((ingredient) => {
    return ingredient.name === updatedIngredient.name
      ? updatedIngredient
      : ingredient
  })
}

export const getNutritionValueByAmountPortion = ({
  amountPortion,
  standardNutrition,
}: {
  amountPortion: number
  standardNutrition: number
}) => {
  return truncateByDecimalPlace(standardNutrition * amountPortion)
}

export const updateNutritionWhenAmountChange = ({
  nextAmount,
  standardIngredient,
}: {
  nextAmount: number
  standardIngredient: IIngredient
}) => {
  const { name, amount, calorie, carbohydrate, fat, protein } =
    standardIngredient

  const amountPortion = nextAmount / amount
  return {
    name,
    amount: nextAmount,

    calorie: getNutritionValueByAmountPortion({
      amountPortion,
      standardNutrition: calorie,
    }),

    carbohydrate: getNutritionValueByAmountPortion({
      amountPortion,
      standardNutrition: carbohydrate,
    }),

    fat: getNutritionValueByAmountPortion({
      amountPortion,
      standardNutrition: fat,
    }),

    protein: getNutritionValueByAmountPortion({
      amountPortion,
      standardNutrition: protein,
    }),
  }
}

export const updateIngredientsWhenPortionChange = ({
  ingredients,
  standardIngredients,
  portion,
}: {
  ingredients: IIngredient[]
  standardIngredients: IIngredient[]
  portion: IPortion
}) => {
  const nextServings = Number(portion.first) / Number(portion.second)

  const getAmountByServings = (servings: number, value?: number) => {
    const nextValue = (value ?? 0) * servings
    return truncateByDecimalPlace(nextValue)
  }

  const nextIngredients = ingredients.map((ingredient) => {
    const standardIngredient = standardIngredients.find(
      ({ name }) => name === ingredient.name,
    )

    if (!standardIngredient) {
      console.error("can not find standard ingredient")
      return ingredient
    }

    if (nextServings == 1) {
      return standardIngredient
    }

    const { amount, calorie, fat, protein, carbohydrate } = standardIngredient

    return {
      ...ingredient,
      amount: getAmountByServings(nextServings, amount),
      calorie: getAmountByServings(nextServings, calorie),
      fat: getAmountByServings(nextServings, fat),
      protein: getAmountByServings(nextServings, protein),
      carbohydrate: getAmountByServings(nextServings, carbohydrate),
    }
  })

  return nextIngredients
}

export const isPortionValid = (portion: { first: number; second: number }) => {
  return portion.first > 0 && portion.second > 0
}

export const getNutritionInformation = (
  ingredients: IIngredient[],
): INutritionInformation => {
  const fatAmount = truncateByDecimalPlace(
    ingredients.reduce((acc, item) => acc + item.fat, 0),
  )
  const carbAmount = truncateByDecimalPlace(
    ingredients.reduce((acc, item) => acc + item.carbohydrate, 0),
  )

  const proteinAmount = truncateByDecimalPlace(
    ingredients.reduce((acc, item) => acc + item.protein, 0),
  )

  const totalCalories = truncateByDecimalPlace(
    ingredients.reduce((acc, item) => acc + item.calorie, 0),
  )

  const totalAmount = truncateByDecimalPlace(
    ingredients.reduce((acc, item) => acc + item.amount, 0),
  )

  const totalNutritionAmount = truncateByDecimalPlace(
    fatAmount + carbAmount + proteinAmount,
  )

  const proteinPercent = truncateByDecimalPlace((proteinAmount / totalNutritionAmount) * 100)
  const fatPercent = truncateByDecimalPlace((fatAmount / totalNutritionAmount) * 100)
  const carbPercent = truncateByDecimalPlace((carbAmount / totalNutritionAmount) * 100)

  return {
    totalAmount,
    totalCalories,
    fatAmount,
    carbAmount,
    proteinAmount,
    proteinPercent,
    fatPercent,
    carbPercent,
  }
}
