import React, { Fragment, useState } from "react"
import { AIResponse, AIText, FoodImageBox, StyledFoodImage } from "./styled"
import { FlexBox } from "components/Common/FlexBox"
import KagamiAvatar from "components/ZozoFitAvatar"
import ChatFormIcon from "./ChatFormIcon"
import CloseChatIcon from "./CloseChatIcon"
import CollapsedChatIcon from "./CollapsedChatIcon"
import TransparentHeader from "components/Common/TransparentHeader"
import { useLogMealStep } from "features/meal/hooks/hooks"
import ClosePreview from "components/Icons/ClosePreview"

const FoodImage = ({
  imageUrl,
  response,
  setImagePreviewerVisible,
  isPreviewing,
}: {
  imageUrl: string
  response: string
  setImagePreviewerVisible: (visible: boolean) => void
  isPreviewing: boolean
}) => {
  const [isShowResponse, setIsShowResponse] = useState(true)
  const setStep = useLogMealStep()
  return (
    <FoodImageBox>
      <TransparentHeader
        onBack={() => {
          setStep("init")
        }}
      />
      <StyledFoodImage
        src={imageUrl}
        alt="food-photo"
        onClick={() => setImagePreviewerVisible(true)}
      />
      {isPreviewing && (
        <ClosePreview onClick={() => setImagePreviewerVisible(false)} />
      )}

      <AIResponse>
        <FlexBox
          $gap={12}
          $alignItems="flex-end"
          style={{ position: "relative" }}
        >
          <KagamiAvatar size="large" />

          {isShowResponse ? (
            <Fragment>
              <ChatFormIcon />
              <CloseChatIcon onClose={() => setIsShowResponse(false)} />
              <AIText>{response}</AIText>
            </Fragment>
          ) : (
            <CollapsedChatIcon onClick={() => setIsShowResponse(true)} />
          )}
        </FlexBox>
      </AIResponse>
    </FoodImageBox>
  )
}

export default FoodImage
