import { SwipeAction } from "antd-mobile"
import styled from "styled-components"

export const StyledMealItem = styled.div`
  display: flex;
  padding-right: var(--spacing_l, 16px);
  align-items: flex-start;
  background: var(--Schemes-Surface-Container-Lowest, #fff);
`

export const MealImageBox = styled.div`
  display: flex;
  width: 53px;
  height: 53px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  background: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));

  flex-shrink: 0;
`

export const MealImage = styled.img`
  border-radius: var(--spacing_xxs, 4px);
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const MealContent = styled.div`
  height: 100%;
  flex-grow: 1;
  padding: 5.2px 0;
  padding-left: 8px;
`

export const MealName = styled.div`
  font-size: 14px;
`

export const MealSlice = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--Schemes-On-Surface-Variant, #69757d);
`

export const MealCalorie = styled.div`
  padding: var(--spacing_xs, 6px) 0px;
`

export const StyledSwipeAction = styled(SwipeAction)`
  --adm-color-danger: #ff4921;

  .adm-swipe-action-actions-right .adm-button {
    width: 93px;
  }

  .adm-swipe-action-content {
    border-top: 0.5px solid rgb(190, 198, 202);
  }
`
