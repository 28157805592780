import React from "react"
import { getSvgFill } from "utils"

const NotSure = ({ isSelected }: { isSelected: boolean }) => {
  const { backgroundFill, pathFill } = getSvgFill(isSelected)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g clip-path="url(#clip0_5655_14682)">
        <path
          d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
          fill={backgroundFill}
        />
        <path
          d="M26.657 25.4612C26.3486 25.3935 25.9801 25.3108 25.9575 24.9498C25.8823 23.7614 26.0252 22.731 26.3411 21.851C23.5582 23.1672 20.1887 25.2732 20.0082 28.1387C19.9255 29.4174 20.467 30.6508 21.6178 31.8241C22.8287 33.0501 24.7015 34.2159 27.2286 35.314C27.4016 35.3892 27.4993 35.5547 27.4993 35.7276V41H36.6677V34.9454C36.6677 34.6972 36.8708 34.4942 37.119 34.4942C37.3672 34.4942 37.5702 34.6972 37.5702 34.9454V41H40.2403L40.2327 34.261C40.2327 31.6963 38.3148 30.3425 35.9306 29.8987C33.9826 32.4108 30.5605 32.5838 28.4169 30.2372C27.2361 30.5079 25.4987 30.696 24.4081 30.1243C23.7087 29.7633 23.3627 29.1391 23.4153 28.3493C23.5056 27.1384 25.4235 26.0855 26.657 25.4612ZM29.0111 24.9648C28.6275 25.2205 28.2139 25.4086 27.7776 25.4838C27.6422 27.0106 28.1161 28.4922 29.0337 29.5452C30.7034 31.4631 33.3884 31.4631 35.0582 29.5452C35.8103 28.6803 36.2916 27.4994 36.3368 26.1908C34.2759 26.2735 31.8165 25.4236 30.5379 23.4906C30.0791 24.0623 29.5752 24.5887 29.0111 24.9648ZM26.8375 24.5812C28.2665 24.9046 29.4473 23.4831 30.222 22.4076C30.4251 22.1293 30.8463 22.1744 30.9892 22.4753C31.9594 24.4834 34.2985 25.3409 36.2164 25.2882C36.5022 25.2807 36.9836 25.2431 37.2243 25.1227C37.3672 22.3399 36.0735 20.4897 34.3662 19.6097C32.8469 18.8275 30.9591 18.7824 29.4398 19.5646C27.898 20.3618 26.7623 22.024 26.8375 24.5812Z"
          fill={pathFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5655_14682">
          <rect width="60" height="60" rx="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotSure
