const CloseChatIcon = ({ onClose }: { onClose: () => void }) => {
  return (
    <svg
      onClick={onClose}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ position: "absolute", right: -8, top: -8, cursor: "pointer" }}
    >
      <rect width="16" height="16" rx="4" fill="#69757D" />
      <g clipPath="url(#clip0_4067_15122)">
        <path
          d="M11.1444 4.86135C10.8933 4.6103 10.4878 4.6103 10.2368 4.86135L7.99935 7.09232L5.76194 4.85491C5.51089 4.60386 5.10535 4.60386 4.8543 4.85491C4.60325 5.10596 4.60325 5.5115 4.8543 5.76255L7.09171 7.99996L4.8543 10.2374C4.60325 10.4884 4.60325 10.894 4.8543 11.145C5.10535 11.3961 5.51089 11.3961 5.76194 11.145L7.99935 8.9076L10.2368 11.145C10.4878 11.3961 10.8933 11.3961 11.1444 11.145C11.3954 10.894 11.3954 10.4884 11.1444 10.2374L8.90699 7.99996L11.1444 5.76255C11.389 5.51794 11.389 5.10596 11.1444 4.86135Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4067_15122">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CloseChatIcon
