import { Fragment, useMemo, useState } from "react"
import { useAppSelector } from "app/hooks"
import EditMealGeneral from "./General"
import {
  useBackToHome,
  useFeedback,
  useIngredientOptions,
  useLogMealStep,
} from "../../hooks/hooks"
import { IIngredient } from "../../types"
import SearchIngredient from "./SearchIngredient"
import AddIngredient from "./AddIngredient"
import { truncateByDecimalPlace } from "utils"
import { useLocalFeedback } from "features/meal/hooks/mealDetailsHooks"
import {
  getStandardIngredient,
  replaceIngredientByName,
  updateNutritionWhenAmountChange,
} from "features/meal/mealHelper"
import EditIngredient from "features/meal/EditMeal/EditIngredient"

const FeedBack = () => {
  useIngredientOptions()
  const step = useAppSelector((state) => state.meal.step)
  const sourceFeedback = useAppSelector((state) => state.meal.feedback)
  const setStep = useLogMealStep()

  const originMeal = useMemo(() => {
    const first = sourceFeedback?.portion?.first ?? 1
    const second = sourceFeedback?.portion?.second ?? 1
    return {
      ...sourceFeedback,
      servings: `${first}/${second}`,
    }
  }, [sourceFeedback])

  const {
    feedback,
    setFeedBack,
    onPortionChange,
    standardIngredients,
    setStandardIngredients,
  } = useLocalFeedback(originMeal as any)

  const backToHome = useBackToHome()
  const { onSubmitFeedback, sendFeedbackLoading } = useFeedback(backToHome)

  const [editingIngredient, setEditingIngredient] = useState<IIngredient>()
  const [addingIngredient, setAddingIngredient] = useState<IIngredient>()
  const [standardIngredient, setStandardIngredient] = useState<IIngredient>()

  const onSelectIngredientToEdit = (ingredient: IIngredient) => {
    if (ingredient) {
      const standardIngredient = standardIngredients.find(
        ({ name }) => name === ingredient.name,
      )
      setStandardIngredient(standardIngredient)
      setEditingIngredient(ingredient)
      setStep("edit-ingredient")
    }
  }

  const onFinishEditing = () => {
    setStep("edit")
    setStandardIngredient(undefined)
    setEditingIngredient(undefined)

    if (feedback && editingIngredient && standardIngredient) {
      const localIngredients = replaceIngredientByName(
        editingIngredient,
        feedback.ingredients,
      )

      setFeedBack({
        ...feedback,
        ingredients: localIngredients,
      })

      const standardIngredient = getStandardIngredient(
        editingIngredient,
        feedback.portion,
      )

      const nextStandardIngredients = replaceIngredientByName(
        standardIngredient,
        standardIngredients,
      )

      setStandardIngredients(nextStandardIngredients)
    }
  }

  const onEditingIngredientAmountChange = (amount?: number) => {
    if (!feedback || !editingIngredient || !standardIngredient) {
      return
    }

    let numberValue = Number(amount)

    if (isNaN(numberValue)) {
      numberValue = 0
    }

    const updatedIngredient = updateNutritionWhenAmountChange({
      nextAmount: numberValue,
      standardIngredient,
    })

    setEditingIngredient(updatedIngredient)
  }

  const onStartAddingIngredient = (addedIngredient: IIngredient) => {
    setAddingIngredient(addedIngredient)
    setStandardIngredient(addedIngredient)
    setStep("add-ingredient")
  }

  const onAddingIngredientAmountChange = (amount: number) => {
    if (!standardIngredient || !addingIngredient) {
      return
    }

    const getNextValue = (originVal: number) => {
      return truncateByDecimalPlace(
        (originVal / standardIngredient.amount) * amount,
        1,
      )
    }
    setAddingIngredient({
      ...addingIngredient,
      amount,
      calorie: getNextValue(standardIngredient.calorie),
      fat: getNextValue(standardIngredient.fat),
      carbohydrate: getNextValue(standardIngredient.carbohydrate),
      protein: getNextValue(standardIngredient.protein),
    })
  }

  const onFinishAdding = () => {
    if (!addingIngredient || !feedback) {
      return
    }

    setFeedBack({
      ...feedback,
      ingredients: [addingIngredient, ...feedback.ingredients],
    })

    const addingStandardIngredient = getStandardIngredient(
      addingIngredient,
      feedback.portion,
    )

    setStandardIngredients([addingStandardIngredient, ...standardIngredients])

    setStep("edit")
    setAddingIngredient(undefined)
    setStandardIngredient(undefined)
  }

  const onDeleteIngredient = () => {
    if (!feedback || !editingIngredient) return

    const nextLocalIngredients = feedback.ingredients.filter(
      ({ name }) => name !== editingIngredient.name,
    )
    setFeedBack({ ...feedback, ingredients: nextLocalIngredients })

    setStandardIngredients(() =>
      standardIngredients.filter(({ name }) => name !== editingIngredient.name),
    )

    setStep("edit")
  }

  const onNameChange = (value: string) => {
    if (!feedback) return

    setFeedBack({ ...feedback, name: value })
  }

  const onSave = () => {
    if (feedback) {
      onSubmitFeedback(feedback)
    }
  }

  return (
    <Fragment>
      {step === "edit" && feedback && (
        <EditMealGeneral
          onSelectIngredientToEdit={onSelectIngredientToEdit}
          feedback={feedback}
          onSave={onSave}
          onNameChange={onNameChange}
          onServingChange={onPortionChange}
          setStep={setStep}
          sendFeedbackLoading={sendFeedbackLoading}
        />
      )}
      {step === "edit-ingredient" && editingIngredient && (
        <EditIngredient
          editingIngredient={editingIngredient}
          onIngredientAmountChange={onEditingIngredientAmountChange}
          onFinishEditing={onFinishEditing}
          onDeleteIngredient={() => onDeleteIngredient()}
        />
      )}

      {step === "search-ingredient" && feedback && (
        <SearchIngredient
          onAddIngredient={onStartAddingIngredient}
          ingredients={feedback?.ingredients}
        />
      )}

      {step === "add-ingredient" && addingIngredient && (
        <AddIngredient
          addingIngredient={addingIngredient}
          onFinishAdding={onFinishAdding}
          onUpdateAddingIngredientAmount={onAddingIngredientAmountChange}
        />
      )}
    </Fragment>
  )
}

export default FeedBack
